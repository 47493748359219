import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobPost } from '../models/jobPost.model';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class SocService {
  private apiUrl = environment.apiUrl;
  private getSocCategoryUrl = `${this.apiUrl}/api/1.0/getSocCategory`;
  private getSOCTitleUrl = `${this.apiUrl}/api/1.0/getSOCTitle`;
  private createJobPostUrl = `${this.apiUrl}/api/1.0/createNewJobPostAsync`;
  private getJobPostStatusTypeUrl = `${this.apiUrl}/api/1.0/getJobPostStatusType`;
  private getSponsorWorkerTypeUrl = `${this.apiUrl}/api/1.0/getSponsorWorkerType`;
  private getHiringProcessTypeUrl = `${this.apiUrl}/api/1.0/getHiringProcessType`;
  private getS3DocumentUrl = `${this.apiUrl}/api/1.0/getS3DocumentAsync`;
  private getJobPostUrl = `${this.apiUrl}/api/1.0/getJobPostAsync`;
  private getDocumentsUrl = `${this.apiUrl}/api/1.0/downloadCompanyOrComplianceDocumentAsync`;
  constructor(private http: HttpClient) { }

  getSocCategories(token: string): Observable<any[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.getSocCategoryUrl, { headers });
  }

  getSOCTitles(searchQuery: string, categoryId: string): Observable<any[]> {
    const params = { SOCTitleCategoryType: categoryId, CombinationSearch: searchQuery };
    return this.http.get<any[]>(this.getSOCTitleUrl, { params });
  }

  createJobPost(token: string, jobPostData: any): Observable<JobPost> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<JobPost>(this.createJobPostUrl, jobPostData, { headers });
  }

  getJobPostStatusTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getJobPostStatusTypeUrl);
  }

  getSponsorWorkerTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getSponsorWorkerTypeUrl);
  }

  getHiringProcessTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getHiringProcessTypeUrl);
  }

  getS3DocumentAsync(docCode: string): Observable<any> {
    const url = `${this.getS3DocumentUrl}?DocCode=${docCode}`;
    return this.http.get<any>(url);
  }

  getJobPosts(userInfoId: number, companyInfoId: number): Observable<any[]> {
    const url = `${this.getJobPostUrl}?UserInfoId=${userInfoId}&CompanyInfoId=${companyInfoId}&SocTitleInfoId=0`;
    return this.http.get<any[]>(url);
  }
  getDocuments(companyInfoId: number, employeeId: number, documentModuleType: number): Observable<any> {
    const url = `${this.getDocumentsUrl}?CompanyInfoId=${companyInfoId}&EmployeeId=${employeeId}&DocumentModuleType=${documentModuleType}`;
    return this.http.get<any>(url);
  }
}
