import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  ComplianceActionDetail,
  ExpiryDocument,
  LoginAccessType,
  SharingDocumentsRequest,
} from '../model/employee.model';
import { environment } from 'src/environment/environment';
import { TokenService } from 'src/app/shared/services/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSelectionService {
  private baseUrl = environment.apiUrl;
  private getS3DocumentApiUrl = `${this.baseUrl}/api/1.0/getS3DocumentAsync`;
  private loginAccessTypeApiUrl = `${this.baseUrl}/api/1.0/getLoginAccessType`;
  private getComplianceActionApiUrl = `${this.baseUrl}/api/1.0/getComplianceActionDetailAsync`;
  private getPendingDocumentsApiUrl = `${this.baseUrl}/api/1.0/getPendingDocumentsAsync`;
  private selectedEmployeeSubject: BehaviorSubject<any | null> =
    new BehaviorSubject<any | null>(null);
  public selectedEmployee$ = this.selectedEmployeeSubject.asObservable();
  private localStorageKey = 'selectedEmployee';
  private sharingDocUrl = `${this.baseUrl}/api/1.0/sharingDocumentsAsync`;
  newEmployeeAdded: EventEmitter<void> = new EventEmitter<void>();
  updatedContentLayout: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient, private tokenService: TokenService) {
    const storedEmployee = localStorage.getItem(this.localStorageKey);
    if (storedEmployee) {
      this.selectedEmployeeSubject.next(JSON.parse(storedEmployee));
    }
  }

  shareDocuments(payload: SharingDocumentsRequest): Observable<any> {
    return this.http.post<any>(this.sharingDocUrl, payload);
  }

  setSelectedEmployee(employee: any): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(employee));
    this.selectedEmployeeSubject.next(employee);
  }

  getS3DocumentAsync(docCode: string): Observable<any> {
    const url = `${this.getS3DocumentApiUrl}?DocCode=${docCode}`;
    return this.http.get<any>(url);
  }

  getLoginAccessTypes(): Observable<LoginAccessType[]> {
    return this.http.get<LoginAccessType[]>(this.loginAccessTypeApiUrl);
  }

  getComplianceActionDetail(
    id: number,
    compSequenceType: number
  ): Observable<ComplianceActionDetail[]> {
    const url = `${this.getComplianceActionApiUrl}?Id=${id}&CompSequenceType=${compSequenceType}`;
    return this.http.get<ComplianceActionDetail[]>(url);
  }

  getPendingDocuments(): Observable<ExpiryDocument[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const selectedEmployeeId = localStorage.getItem('selectedEmployeeId');
    const roleInfoId = this.tokenService.getRoleInfoIdFromToken();
    const url = `${this.getPendingDocumentsApiUrl}?CompanyInfoId=${companyInfoId}&EmployeeId=${selectedEmployeeId}&RoleInfoId=${roleInfoId}`;

    return this.http.get<ExpiryDocument[]>(url);
  }
}
