<div *ngFor="let response of apiResponse">
  <div class="card">
    <div class="card-body p-0">
      <div class="divEmployeeComplianceChart">
        <div class="responsive-table">
          <table class="table table-bordered table-hover mb-0">
            <thead class="table-secondary">
              <tr>
                <th colspan="5" class="text-center position-relative">
                  Employee's Compliance
                  <mat-icon
                    matTooltip="Redirect"
                    class="text-primary"
                    style="
                      font-size: 26px;
                      width: 26px;
                      height: 26px;
                      vertical-align: middle;
                      margin-top: -5px;
                      position: absolute;
                      right: 8px;
                      cursor: pointer;
                    "
                    (click)="navigateTo()"
                    >launch</mat-icon
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  response.employeeDetails &&
                    response.employeeDetails.length > 0;
                  else noData
                "
              >
                <tr
                  *ngFor="let employee of response.employeeDetails"
                  [@barAnimation]="calculatePercentage(employee)"
                >
                  <td>{{ employee.employeeName }}</td>
                  <td class="text-center" style="width: 100px">
                    <div
                      style="cursor: context-menu"
                      class="btn btn-sm btn-secondary py-0 px-2 text-white txt-document-count"
                    >
                      {{ employee.completedDocument }}/{{
                        employee.totalDocument
                      }}
                    </div>
                  </td>
                  <td class="w-50">
                    <div class="progress">
                      <div
                        class="progress-bar bg-success-soft"
                        [style.width.%]="calculatePercentage(employee)"
                      >
                        {{ calculatePercentage(employee) | number : "1.2-2" }}%
                      </div>
                      <div
                        class="progress-bar bg-danger-soft"
                        [style.width.%]="100 - calculatePercentage(employee)"
                      >
                        {{
                          100 - calculatePercentage(employee)
                            | number : "1.2-2"
                        }}%
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <span
                      class="material-icons eye-icon text-primary"
                      matTooltip="View Compliance"
                      (click)="
                        storeEmployeeId(
                          employee.employeeId,
                          employee.firstName,
                          employee.lastName,
                          employee.employeeCode
                        )
                      "
                      >visibility</span
                    >
                  </td>
                </tr>
              </ng-container>
              <ng-template #noData>
                <tr>
                  <td colspan="3" class="text-center">No Data</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
