import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appUppercaseInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UppercaseInputDirective),
      multi: true
    }
  ]
})
export class UppercaseInputDirective implements ControlValueAccessor {
  constructor(private el: ElementRef) {}

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.el.nativeElement.value = value ? value.toUpperCase() : '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  @HostListener('input') onInput() {
    const value: string = this.el.nativeElement.value.toUpperCase();
    this.el.nativeElement.value = value;
    this.onChange(value);
    this.onTouched();
  }
}
