import { Component, Input, OnInit } from '@angular/core';
import { ApiResponse, Employee } from '../model/chart.model';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-employee-comp',
  templateUrl: './employee-comp.component.html',
  styleUrls: ['./employee-comp.component.scss'],
  animations: [
    trigger('barAnimation',[])
  ]
})
export class EmployeeCompComponent implements OnInit {
  @Input() employee!: { completedDocument: number; totalDocument: number };

  apiResponse!: ApiResponse[];
  employees: Employee[] = [];
  companyInfoId!: number;
  employeeId!: number | null;

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.employeeId =
      Number(localStorage.getItem('selectedEmployeeId')) || null;
    this.fetchEmployeeData();
    this.updateEmployeeComp();
  }

  fetchEmployeeData(): void {
    if (this.employeeId !== null) {
      this.dashboardChartService
        .getEmployeeCompliance(this.companyInfoId, this.employeeId)
        .subscribe((data: ApiResponse[]) => {
          this.apiResponse = data;
        });
    }
  }

  calculatePercentage(employee: any): number {
    return (employee.completedDocument / employee.totalDocument) * 100;
  }

  updateEmployeeComp(): void {
    this.dashboardChartService.updatedEmployeeComp.subscribe(() => {
      this.fetchEmployeeData();
    });
  }
}
