import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  
  constructor(public jwtHelper: JwtHelperService) {}

  getUserInfoIdFromToken(): number {
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken.UserInfoId ? +decodedToken.UserInfoId : 0;
    }
    return 0;
  }

  getCompanyInfoIdFromToken(): number {
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken.CompanyInfoId ? +decodedToken.CompanyInfoId : 0;
    }
    return 0;
  }

  getRoleInfoIdFromToken(): number {
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        ? +decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        : 0;
    }
    return 0;
  }

  getEmployeeIdFromToken(): number {
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken.EmployeeId ? +decodedToken.EmployeeId : 0;
    }
    return 0;
  }
}
