import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableRightClick]'
})
export class DisableRightClickDirective {

  // Disable right-click
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    event.preventDefault();
  }

  // Disable F12, Ctrl+Shift+I, and Ctrl+Shift+C for opening developer tools
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // F12
    if (event.key === 'F12') {
      event.preventDefault();
    }

    // Ctrl+Shift+I or Ctrl+Shift+C
    if ((event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'C'))) {
      event.preventDefault();
    }

    // Ctrl+Shift+J (open console in some browsers)
    if (event.ctrlKey && event.shiftKey && event.key === 'J') {
      event.preventDefault();
    }

    // Ctrl+U (open page source)
    if (event.ctrlKey && event.key === 'U') {
      event.preventDefault();
    }
  }
}
