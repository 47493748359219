<!-- <div class="overlay">
  <div class="progress-animation">
    <mat-spinner></mat-spinner>
    <p class="text-center mt-2 mb-0">Please wait...</p>
  </div>
</div> -->
<div class="overlay">
  <div class="progress-animation bg-transparent" >
    <img src="assets/Icons/Comp 7_1.gif" alt="Loading..." style="width: 100px; height: 100px; "  />
    <p class="text-center mt-2 mb-0 text-white" >Please wait...</p>
  </div>
</div>
