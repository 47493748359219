import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environment';
import {
  ComplianceSequence,
  SequenceType,
} from '../models/compliance-sequence.model';

@Injectable({
  providedIn: 'root',
})
export class ComplianceSequenceService {
  private baseApiUrl = environment.apiUrl;
  private getComplianceSequenceApiUrl = `${this.baseApiUrl}/api/1.0/getComplianceSequenceAsync`;
  private getSequenceApiUrl = `${this.baseApiUrl}/api/1.0/getSequenceType`;

  constructor(private http: HttpClient) {}

  getComplianceSequence(
    companyInfoId: number,
    employeeId: number
  ): Observable<ComplianceSequence[]> {
    const url = `${this.getComplianceSequenceApiUrl}?CompanyInfoId=${companyInfoId}&EmployeeId=${employeeId}`;
    return this.http.get<ComplianceSequence[]>(url);
  }

  getSequenceType(): Observable<SequenceType[]> {
    return this.http.get<SequenceType[]>(this.getSequenceApiUrl);
  }
}
