import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  CardApiResponse,
  CompanyDocCardType,
  DashboardPopupData,
  EmployeeChart,
} from '../model/chart.model';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardChartService {
  private baseApiUrl = environment.apiUrl;
  private employeeChartApiUrl = `${this.baseApiUrl}/api/1.0/getDashboardEmployeeDocStatusAsync`;
  private getCardApiUrl = `${this.baseApiUrl}/api/1.0/getCardTypeNinetySixtyAsync`;
  private getDashboardEmployeeListApiUrl = `${this.baseApiUrl}/api/1.0/getDashboardEmployeeListAsync`;
  private getDashboardCompanyDocumentTypeApiUrl = `${this.baseApiUrl}/api/1.0/getDashboardCompanyDocumentTypeAsync`;
  private getDashboardCompanyDocumentApiUrl = `${this.baseApiUrl}/api/1.0/getDashboardCompanyDocumentListAsync`;
  private getCompanyDocCardTypeApiUrl = `${this.baseApiUrl}/api/1.0/getCompanyDocCardTypeNinetySixtyAsync`;
  updatedEmployeeComp: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient, 
    private tokenService: TokenService
  ) {}

  getEmployeeData(companyInfoId: number): Observable<ApiResponse[]> {
    return this.http.get<ApiResponse[]>(`${this.employeeChartApiUrl}?CompanyInfoId=${companyInfoId}`);
  }

  getCardData(companyInfoId: number): Observable<CardApiResponse[]> {
    return this.http.get<CardApiResponse[]>(`${this.getCardApiUrl}?CompanyInfoId=${companyInfoId}`);
  }

  getEmployeeList(companyInfoId: number, cardType: number, expirdType: number): Observable<EmployeeChart[]> {
    const url = `${this.getDashboardEmployeeListApiUrl}?CompanyInfoId=${companyInfoId}&CardType=${cardType}&ExpirdType=${expirdType}`;
    return this.http.get<EmployeeChart[]>(url);
  }

  getCompanyDocumentTypes(companyInfoId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.getDashboardCompanyDocumentTypeApiUrl}?CompanyInfoId=${companyInfoId}`);
  }

  getEmployeeDocumentStatus(companyInfoId: number): Observable<ApiResponse[]> {
    return this.http.get<ApiResponse[]>(`${this.employeeChartApiUrl}?CompanyInfoId=${companyInfoId}`);
  }

  getEmployeeCompliance(companyInfoId: number, employeeId: number): Observable<ApiResponse[]> {
    return this.http.get<ApiResponse[]>(`${this.employeeChartApiUrl}?CompanyInfoId=${companyInfoId}&EmployeeId=${employeeId}`);
  }

  getCompanyDocCardType(): Observable<CompanyDocCardType[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const url = `${this.getCompanyDocCardTypeApiUrl}?CompanyInfoId=${companyInfoId}`;
    return this.http.get<CompanyDocCardType[]>(url);
  }

  getCompanyDocumentList(companyInfoId: number, expirdType: number, listIds: string): Observable<DashboardPopupData[]> {
    const params = {
      CompanyInfoId: companyInfoId.toString(),
      ExpirdType: expirdType.toString(),
      ListIds: listIds,
    };
    return this.http.get<DashboardPopupData[]>(this.getDashboardCompanyDocumentApiUrl, { params });
  }
}
