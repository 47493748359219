import { Component, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { ApiResponse, Employee } from '../model/chart.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-chart',
  templateUrl: './employee-chart.component.html',
  styleUrls: ['./employee-chart.component.scss'],
  animations: [
    trigger('barAnimation', [
      transition(
        '* => *',
        [
          style({ width: '0%' }),
          animate('2s', style({ width: '{{percentage}}%' })),
        ],
        { params: { percentage: 0 } }
      ),
    ]),
  ],
})
export class EmployeeChartComponent {
  @Input() employee!: { completedDocument: number; totalDocument: number };

  apiResponse!: ApiResponse[];
  employees: Employee[] = [];
  companyInfoId!: number;

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.fetchEmployeeData();
  }

  fetchEmployeeData(): void {
    this.dashboardChartService
      .getEmployeeData(this.companyInfoId)
      .subscribe((data: ApiResponse[]) => {
        this.apiResponse = data;
      });
  }

  calculatePercentage(employee: any): number {
    return (employee.completedDocument / employee.totalDocument) * 100;
  }

  storeEmployeeId(
    employeeId: number,
    firstName: string,
    lastName: string,
    employeeCode: string
  ): void {
    localStorage.setItem('selectedEmployeeId', employeeId.toString());
    localStorage.setItem('selectedEmployeeFirstName', firstName);
    localStorage.setItem('selectedEmployeeLastName', lastName);
    localStorage.setItem('selectedEmployeeCode', employeeCode);
    this.router.navigate([
      '/content/employee-info/employee-details/compliance-document',
    ]);
  }

  navigateTo(): void {
    this.router.navigate(['/content/employee-info']);
  }
}
