import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent {
  sanitizedUrl: any;
  
  constructor(
    private dialogRef: MatDialogRef<VideoDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);}


  onNoClick(): void {
    this.dialogRef.close();
  }
}
