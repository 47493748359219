import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { EmployeeChartComponent } from './employee-chart/employee-chart.component';
import { ExpiryTypesComponent } from './expiry-types/expiry-types.component';
import { MatIconModule } from '@angular/material/icon';
import { CompanyExpiryComponent } from './company-expiry/company-expiry.component';
import { EmployeeCountComponent } from './employee-count/employee-count.component';
import { CompanyChartComponent } from './company-chart/company-chart.component';
import { NgxApexchartsModule } from 'ngx-apexcharts';
import { LoadingAnimationModule } from 'src/app/shared/modules/loading-animation.module';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeDonutComponent } from './employee-donut/employee-donut.component';
import { IonicModule } from '@ionic/angular';
import { EmployeeCompComponent } from './employee-comp/employee-comp.component';
import { SubscriptionAlertComponent } from './subscription-alert/subscription-alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CompanyCountComponent } from './company-count/company-count.component';
import { CompanyPopupComponent } from './company-popup/company-popup.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DashboardComponent,
    EmployeeChartComponent,
    ExpiryTypesComponent,
    CompanyExpiryComponent,
    EmployeeCountComponent,
    CompanyChartComponent,
    EmployeeListComponent,
    EmployeeDonutComponent,
    EmployeeCompComponent,
    SubscriptionAlertComponent,
    CompanyCountComponent,
    CompanyPopupComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatIconModule,
    NgxApexchartsModule,
    LoadingAnimationModule,
    MatTooltipModule,
    IonicModule.forRoot(),
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    EmployeeCountComponent,
    ExpiryTypesComponent,
    EmployeeDonutComponent,
    EmployeeCompComponent,
  ],
})
export class DashboardModule {}
