import { Component, OnInit } from '@angular/core';
import { CardApiResponse, CardType, EmployeeChart } from '../model/chart.model';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeListComponent } from '../employee-list/employee-list.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-expiry-types',
  templateUrl: './expiry-types.component.html',
  styleUrls: ['./expiry-types.component.scss'],
})
export class ExpiryTypesComponent implements OnInit {
  employees: EmployeeChart[] = [];
  passportValue: CardType | undefined;
  brpValue!: CardType;
  rightToWorkValue!: CardType;
  cosValue!: CardType;
  cardType: number = 0;
  expirdType: number = 0;
  companyInfoId!: number;

  labelBackgrounds: { [key: number]: string } = {
    1: 'bg-success',
    2: 'bg-info',
    3: 'bg-secondary',
    4: 'bg-warning',
    5: 'bg-danger',
  };

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.fetchCardData();
  }

  fetchCardData(): void {
    this.dashboardChartService
      .getCardData(this.companyInfoId)
      .subscribe((data: CardApiResponse[]) => {
        this.passportValue = data[0].passportValue;
        this.brpValue = data[0].brpValue;
        this.rightToWorkValue = data[0].rightToWorkValue;
        this.cosValue = data[0].cosValue;
      });
  }

  fetchEmployeeList(): void {
    this.dashboardChartService
      .getEmployeeList(this.companyInfoId, this.cardType, this.expirdType)
      .subscribe((data: EmployeeChart[]) => {
        if (data && data.length >= 0) {
          this.openEmployeeDialog(data);
        } else {
          console.error('No employee data available.');
        }
      });
  }

  openEmployeeDialog(data: EmployeeChart[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.width = '90%';
    dialogConfig.height = '90%';
    this.dialog.open(EmployeeListComponent, dialogConfig);
  }

  handleCardAndExpiredTypeClick(cardOrExpiredType: any): void {
    this.cardType = cardOrExpiredType.cardType;
    this.expirdType = cardOrExpiredType.expiredType.expirdType;
    this.fetchEmployeeList();
  }

  handleNoData() {
    this.toastr.error('No documents in selected category!');
  }

  getLabelBackground(expirdTypeID: number): string {
    return this.labelBackgrounds[expirdTypeID] || 'bg-primary';
  }
}
