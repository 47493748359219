import { Component, OnInit } from '@angular/core';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-company-expiry',
  templateUrl: './company-expiry.component.html',
  styleUrls: ['./company-expiry.component.scss'],
})
export class CompanyExpiryComponent implements OnInit {
  documentTypes: any[] = [];
  companyInfoId!: number;

  expirationTypes = [
    { key: 1, value: '90 Days & Below' },
    { key: 2, value: '60 Days & Below' },
    { key: 3, value: '30 Days & Below' },
    { key: 4, value: '7 Days & Below' },
    { key: 5, value: 'Expired' },
  ];

  headerBackgrounds: { [key: number]: string } = {
    1: 'bg-success-50',
    2: 'bg-info-50',
    3: 'bg-secondary-50',
    4: 'bg-warning-50',
    5: 'bg-danger-50',
  };

  documentBackgrounds: { [key: number]: string } = {
    1: 'bg-success-25',
    2: 'bg-info-25',
    3: 'bg-secondary-25',
    4: 'bg-warning-25',
    5: 'bg-danger-25',
  };

  bodyBackgrounds: { [key: number]: string } = {
    1: 'bg-success-10',
    2: 'bg-info-10',
    3: 'bg-secondary-10',
    4: 'bg-warning-10',
    5: 'bg-danger-10',
  };

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.getCompanyDocumentData();
  }

  getCompanyDocumentData(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.dashboardChartService
      .getCompanyDocumentTypes(this.companyInfoId)
      .subscribe(
        (data) => {
          if (data && data.length > 0 && data[0].expiredType) {
            this.documentTypes = data[0].expiredType;
          } else {
            console.error('No document types found in API response.');
          }
        },
        (error) => {
          console.error('Error fetching document types:', error);
        }
      );
  }

  getHeaderBackground(expirationTypeKey: number): string {
    return this.headerBackgrounds[expirationTypeKey] || 'bg-primary-50';
  }

  getDocumentBackground(expirationTypeKey: number): string {
    return this.documentBackgrounds[expirationTypeKey] || 'bg-primary-25';
  }

  getBodyBackground(expirationTypeKey: number): string {
    return this.bodyBackgrounds[expirationTypeKey] || 'bg-primary-10';
  }
}
