import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private readonly protectedEndpoints = [
    '/api/admin/1.0/auth',
    '/api/1.0/auth',
    '/api/1.0/authUserLogin',
    '/api/1.0/authVerifyEmailAsync',
    '/api/1.0/authCustomerSupportLogin',
    '/api/1.0/auth_admin',
    '/api/1.0/authForgetPasswordVerifyEmailAsync',
    '/api/1.0/authUserRegisterPasswordAsync',
    '/api/1.0/getSector',
    '/api/1.0/getAvailableBookingLanguage',
    '/api/1.0/getPriorityTypeByLang',
    '/api/1.0/getDocumentModuleType',
    '/api/1.0/getDocumentMandatoryType',
    '/api/1.0/getBookingStatusType',
    '/api/1.0/getCompanyType',
    '/api/1.0/getNoofEmployees',
    '/api/1.0/getVerificationType',
    '/api/1.0/getVerificationStatusType',
    '/api/1.0/getCountryCodeType',
    '/api/1.0/getLanguageType',
    '/api/1.0/getPriorityType',
    '/api/1.0/getButtonStateType',
    '/api/1.0/getAddressType',
    '/api/1.0/getCompLanguageType',
    '/api/1.0/getSubscriptionPlanInfo',
    '/api/sc/1.0/get_company_house',
    '/api/1.0/sendVerificaionMessage',
    '/api/1.0/validateOTP',
    'api/1.0/createNewUserAsync',
    'api/1.0/validatePromoCodeAsync',
    'api/1.0/authUpdatePasswordAsync',
    '/api/1.0/validateSharingDocumentInfoAsync',
    'api/1.0/downloadCompanyOrComplianceDocumentAsync',
    '/api/1.0/getS3DocumentAsync',
  ];

  private readonly apiKey = 'QDFJ3GOyHB2GJbPDweg5C7iNmnl615EX22CeihIT';

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    const headersConfig: { [key: string]: string } = {
      'Content-Type': 'application/json',
      Server: 'Microsoft-IIS/10.0',
      'WWW-Authenticate': 'Bearer',
      'X-Frame-Options': 'DENY',
      'X-Powered-By': 'ASP.NET',
      'X-Powered-By-Plesk': 'PleskWin',
    };
    if (
      this.protectedEndpoints.some((endpoint) => request.url.includes(endpoint))
    ) {
      headersConfig['Api-key'] = this.apiKey;
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
      }
    } else {
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
      }
    }

    const modifiedRequest = request.clone({
      setHeaders: headersConfig,
    });

    return next.handle(modifiedRequest);
  }
}
