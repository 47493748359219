import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenService } from './jwt.service';
import { SubscriptionStatus } from '../models/subscription.model';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root',
})
export class SubscriptionPaymentService {
  private apiUrl = `${environment.apiUrl}/api/1.0/getSubscriptionExpiredAsync`;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  fetchSubscriptionStatus(): Observable<boolean> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    return this.http.get<SubscriptionStatus[]>(`${this.apiUrl}?CompanyInfoId=${companyInfoId}`)
      .pipe(
        map(response => response[0]?.subscriptionExpiredType === 1),
        catchError(() => of(false))
      );
  }
}
