import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environment';
import { ActionType } from '../models/action-type.model';

@Injectable({
  providedIn: 'root'
})
export class ActionTypeService {

  private baseApiUrl = environment.apiUrl;
  private getActionTypesUrl = `${this.baseApiUrl}/api/1.0/getActionType`;

  constructor(private http: HttpClient) {}

  getActionTypes(actionType: number): Observable<ActionType[]> {
    return this.http.get<ActionType[]>(`${this.getActionTypesUrl}?ActionType=${actionType}`);
  }
}
