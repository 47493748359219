import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginService } from './shared/services/auth.service';
import { RegisterService } from './shared/services/register.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { JwtModule } from '@auth0/angular-jwt';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { EmployeeLayoutComponent } from './layouts/employee-layout/employee-layout.component';
import { MatListModule } from '@angular/material/list';
import { IonicModule } from '@ionic/angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardModule } from './dashboard/dashboard/dashboard.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationModule } from './shared/modules/notification.module';
import { DocumentPreviewComponent } from './shared/document-preview/document-preview.component';
import { HttpInterceptorService } from './shared/interceptor/http.interceptor';
import { MatExpansionModule } from '@angular/material/expansion';
import { FullCalendarModule } from '@fullcalendar/angular';
import { InputDirectiveModule } from './shared/directives/inputdirective.module';
import { VideoDialogComponent } from './shared/video-dialog/video-dialog.component';
import { EmployeeInfoDeleteComponent } from './shared/components/employee-info-delete/employee-info-delete.component';
import { DeleteConfirmationComponent } from './shared/components/delete-confirmation/delete-confirmation.component';
import { DocVerifyConfirmationComponent } from './shared/components/doc-verify-confirmation/doc-verify-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    ContentLayoutComponent,
    EmployeeLayoutComponent,
    DocumentPreviewComponent,
    VideoDialogComponent,
    EmployeeInfoDeleteComponent,
    DeleteConfirmationComponent,
    DocVerifyConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    FullCalendarModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    DashboardModule,
    InputDirectiveModule,
    IonicModule,
    MatTooltipModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NotificationModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      maxOpened: 1, // Maximum number of toasts that can be displayed at a time
      autoDismiss: true, // Automatically dismiss old toasts
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('access_token');
        },
        allowedDomains: [],
        disallowedRoutes: [],
      },
    }),
  ],
  providers: [
    RegisterService,
    LoginService,
    provideToastr({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1, // Maximum number of toasts that can be displayed at a time
      autoDismiss: true, // Automatically dismiss old toasts
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
