import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EncryptService } from './encrypt.service';
import { Login, UserInfo } from '../models/login.model';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private baseApiUrl = environment.apiUrl;
  private loginApiUrl = `${this.baseApiUrl}/api/1.0/authUserLogin`;
  private userInfoApiUrl = `${this.baseApiUrl}/api/1.0/authVerifyEmailAsync`;

  constructor(
    private http: HttpClient,
    private encryptService: EncryptService
  ) { }

  login(loginData: Login) {
    const encryptedPassword = this.encryptService.encryptPassword(loginData.password);
    const payload = {
      mobileNoOrEmailId: loginData.mobileNoOrEmailId,
      password: encryptedPassword,
      companyInfoId: loginData.companyInfoId,
      roleInfoId: loginData.roleInfoId
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'accept': 'text/plain'
    });
    console.log('Login request payload:', payload); // Log the payload before sending
    return this.http.post<any>(this.loginApiUrl, payload, { headers: headers }).pipe(
      map(response => {
        console.log('Login response:', response); // Log the response from the API
        if (response && response.token && response.token !== 'Wrong Emailid & Password..!') {
          return response.token; // Return the JWT token
        } else {
          return null;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Login error:', error); // Log any errors
        if (error.status === 400) {
          return throwError('Invalid email or password');
        } else {
          return throwError('Unknown error occurred');
        }
      })
    );
  }

  getUserInfo(emailOrMobile: string): Observable<UserInfo[]> {
    const url = `${this.userInfoApiUrl}?MobileNoOrEmailId=${emailOrMobile}`;
    return this.http.get<UserInfo[]>(url);
  }
}
