import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private hubConnection: HubConnection;
  public messages$ = new BehaviorSubject<any[]>([]);
  constructor() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl('https://devapi.esmacs.com/NotificationHub', {
        accessTokenFactory: () => this.getTokenFromLocalStorage(),
      })
      .build();

    this.startConnection();
    this.registerToReceiveMessages();
    this.registerToReceiveMessages1();
    this.registerToReceiveTaskKeyPressNotify();
    this.registerToReceiveGroupMessages();
  }

  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR connection established');
      })
      .catch((err) => {
        console.error('Error establishing SignalR connection', err);
      });
    this.hubConnection.onreconnected(() => {
      console.log('Reconnected to SignalR');
    });

    this.hubConnection.onreconnecting(() => {
      console.log('Reconnecting to SignalR...');
    });

    this.hubConnection.onclose(() => {
      console.error('Connection to SignalR closed');
    });
  }

  public sendMessage(
    taskInfoId: number,
    message: string,
    employeeListIds: any[]
  ) {
    const payload = {
      NotificationHubType: 2,
      TaskInfoId: taskInfoId,
      TaskChatInfoId: 0,
      Remarks: message,
      employeeListIds: employeeListIds,
    };

    this.hubConnection
      .invoke('SendMessage', payload)
      .then(() => console.log('Message sent successfully'))
      .catch((err) => console.error('Error sending message:', err));
  }

  private registerToReceiveMessages() {
    this.hubConnection.on('TaskChatNotify', (messageData) => {
      console.log('Received data from TaskChatNotify:', messageData);

      const {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        employeeListIds,
      } = messageData;

      const receivedMessage = {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        employeeListIds,
      };
      console.log('Received message from SignalR:', receivedMessage);
      const currentMessages = this.messages$.getValue();
      this.messages$.next([...currentMessages, receivedMessage]);
    });
  }

  private registerToReceiveMessages1() {
    this.hubConnection.on('TaskHistoryNotify', (messageData) => {
      console.log('Received data from TaskChatNotify:', messageData);
      const {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        userName,
        employeeListIds,
      } = messageData;
      const receivedMessage = {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        userName,
        employeeListIds,
      };
      console.log('Received message from SignalR:', receivedMessage);

      const currentMessages = this.messages$.getValue();
      this.messages$.next([...currentMessages, receivedMessage]);
    });
  }

  private registerToReceiveTaskKeyPressNotify() {
    this.hubConnection.on('TaskKeyPressNotify', (messageData) => {
      console.log('Received data from TaskKeyPressNotifySiganlR:', messageData);
      const {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        userName,
        employeeListIds,
      } = messageData;
      const receivedMessage = {
        notificationHubType,
        taskInfoId,
        taskChatInfoId,
        remarks,
        userName,
        employeeListIds,
      };
      console.log(
        'Received message from SignalR notify Type:',
        receivedMessage
      );
      const currentMessages = this.messages$.getValue();
      this.messages$.next([...currentMessages, messageData]);
    });
  }
  private registerToReceiveGroupMessages() {
    this.hubConnection.on('PublicChatNotify', (messageData) => {
      console.log('Received data for GroupChat:', messageData);

      const { notificationHubType, userName, employeeListIds,chatInfoId } = messageData;

      const receivedMessage = {
        notificationHubType,
        userName,
        employeeListIds,
        chatInfoId
      };
      // console.log('Received message from SignalR for GroupChat:', receivedMessage);
      const currentMessages = this.messages$.getValue();
      this.messages$.next([...currentMessages, receivedMessage]);
    });
  }
  private getTokenFromLocalStorage(): string {
    return localStorage.getItem('token') || '';
  }
  public clearMessages(): void {
    this.messages$.next([]);
  }
}
