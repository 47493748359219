import { Component, Inject, OnInit } from '@angular/core';
import { ActionType } from '../../models/action-type.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionTypeService } from '../../services/action-type.service';

@Component({
  selector: 'app-employee-info-delete',
  templateUrl: './employee-info-delete.component.html',
  styleUrls: ['./employee-info-delete.component.scss']
})
export class EmployeeInfoDeleteComponent implements OnInit{
  actionTypes: ActionType[] = [];
  actionType: number = 0; 
  
  constructor(
    public dialogRef: MatDialogRef<EmployeeInfoDeleteComponent>,
    private actionTypeService: ActionTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getActionType(this.actionType);
  }

  getActionType(actionType: number): void {
    this.actionTypeService.getActionTypes(actionType).subscribe(actionTypes => {
      this.actionTypes = actionTypes;
    });
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  onYesClick(selectedActionType: number) {
    if (selectedActionType === 4) {
      this.dialogRef.close(selectedActionType);
    } else {
      this.dialogRef.close('no');
    }
  }
}
