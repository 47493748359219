<div class="row">
  <div class="col-md-4 mb-3" *ngFor="let expirationType of expirationTypes">
    <div class="card" style="height: 280px !important; overflow-y: auto">
      <div
        class="card-header py-2 px-2"
        [ngClass]="getHeaderBackground(expirationType.key)"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0 fw-bold">{{ expirationType.value }}</h3>
          <h3 class="mb-0 fw-bold d-none">Total -</h3>
        </div>
      </div>
      <div
        class="card-body p-2"
        [ngClass]="getBodyBackground(expirationType.key)"
      >
        <div *ngFor="let document of documentTypes">
          <div *ngIf="document.expirdType === expirationType.key">
            <p
              class="mb-2 bg-secondary px-2 py-1"
              style="font-size: 13px; border-radius: 6px"
              [ngClass]="getDocumentBackground(expirationType.key)"
            >
              {{ document.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
