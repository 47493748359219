import { Component } from '@angular/core';
import { ApiResponse, Employee } from '../model/chart.model';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-employee-count',
  templateUrl: './employee-count.component.html',
  styleUrls: ['./employee-count.component.scss'],
})
export class EmployeeCountComponent {
  apiResponse!: ApiResponse[];
  employees: Employee[] = [];
  companyInfoId!: number;

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.fetchEmployeeData();
    this.updateEmployeeComp();
  }

  fetchEmployeeData(): void {
    this.dashboardChartService
      .getEmployeeData(this.companyInfoId)
      .subscribe((data: ApiResponse[]) => {
        this.apiResponse = data;
      });
  }

  updateEmployeeComp(): void {
    this.dashboardChartService.updatedEmployeeComp.subscribe(() => {
      this.fetchEmployeeData();
    });
  }
}
