<div *ngFor="let response of apiResponse">
  <div class="card">
    <div class="card-body p-0">
      <div class="divEmployeeComplianceChart">
        <div class="responsive-table">
          <table class="table table-bordered table-hover mb-0">
            <thead class="table-secondary d-none">
              <tr>
                <th colspan="5" class="text-center">Employee's Compliance</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  response.employeeDetails &&
                    response.employeeDetails.length > 0;
                  else noData
                "
              >
                <tr
                  *ngFor="let employee of response.employeeDetails"
                  [@barAnimation]="calculatePercentage(employee)"
                >
                  <!-- <td>{{ employee.employeeName }}</td> -->
                  <td class="text-center" style="width: 70px">
                    <div
                      class="btn btn-sm btn-secondary py-0 px-2 text-white txt-document-count"
                    >
                      {{ employee.completedDocument }}/{{
                        employee.totalDocument
                      }}
                    </div>
                  </td>
                  <td class="w-60">
                    <div class="progress">
                      <div
                        class="progress-bar bg-success-soft"
                        [style.width.%]="calculatePercentage(employee)"
                      >
                        {{ calculatePercentage(employee) | number : "1.2-2" }}%
                      </div>
                      <div
                        class="progress-bar bg-danger-soft"
                        [style.width.%]="100 - calculatePercentage(employee)"
                      >
                        {{
                          100 - calculatePercentage(employee)
                            | number : "1.2-2"
                        }}%
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noData>
                <tr>
                  <td colspan="3" class="text-center">No Data</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
