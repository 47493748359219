import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mobileNoValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const mobileNo = control.value;
    if (mobileNo && !mobileNo.startsWith('07')) {
      return { mobileNoValidator: true };
    }
    return null;
  };
}
