import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApprovalType,
  EmployeeLeaveLimitResponse,
  LeaveApproval,
  LeaveDateInfoResponse,
  LeaveType,
  UpdateLeaveLimitRequest,
} from '../model/leave-request.model';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class LeaveRequestService {
  private baseUrl = environment.apiUrl;
  private getLeaveTypeApiUrl = `${this.baseUrl}/api/1.0/getLeaveTypeAsync`;
  private getEmployeeLeaveLimitApiUrl = `${this.baseUrl}/api/1.0/getEmployeeLeaveLimitAsync`;
  private updateLeaveLimitApiUrl = `${this.baseUrl}/api/1.0/updateLeaveLimitAsync`;
  private leaveDateInfoApiUrl = `${this.baseUrl}/api/1.0/getLeaveDateInfoAsync`;
  private updateLeaveApprovalUrl = `${this.baseUrl}/api/1.0/updateLeaveApprovalAsync`;
  private getApprovalTypeUrl = `${this.baseUrl}/api/1.0/getApprovalType`;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getLeaveTypes(CompanyInfoId: number, EmployeeId: number): Observable<LeaveType[]> {
    return this.http.get<LeaveType[]>(
      `${this.getLeaveTypeApiUrl}?CompanyInfoId=${CompanyInfoId}&EmployeeId=${EmployeeId}`
    );
  }

  getEmployeeLeaveLimits(
    companyInfoId: number,
    leaveDateInfoId: number,
    employeeId:number,
  ): Observable<EmployeeLeaveLimitResponse[]> {
    let url = `${this.getEmployeeLeaveLimitApiUrl}?CompanyInfoId=${companyInfoId}&LeaveDateInfoId=${leaveDateInfoId}&EmployeeId=${employeeId}`;
    const roleId = this.tokenService.getRoleInfoIdFromToken();
    if (roleId === 5) {
      const employeeId = localStorage.getItem('selectedEmployeeId');
      if (employeeId) {
        url += `&EmployeeId=${employeeId}`;
      } else {
        console.error('EmployeeId not found in localStorage');
      }
    } else {
      const employeeId = localStorage.getItem('defaultEmployeeId');
      if (employeeId) {
        url += `&EmployeeId=${employeeId}`;
      }
    }

    return this.http.get<EmployeeLeaveLimitResponse[]>(url);
  }

  updateLeaveLimit(data: UpdateLeaveLimitRequest): Observable<any> {
    return this.http.post<any>(this.updateLeaveLimitApiUrl, data, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
  }

  getLeaveDateInfo(companyInfoId: number): Observable<LeaveDateInfoResponse[]> {
    return this.http.get<LeaveDateInfoResponse[]>(
      `${this.leaveDateInfoApiUrl}?CompanyInfoId=${companyInfoId}`
    );
  }

  updateLeaveApproval(leaveApproval: LeaveApproval): Observable<any> {
    return this.http.post<any>(this.updateLeaveApprovalUrl, leaveApproval);
  }

  getApprovalType(): Observable<ApprovalType[]> {
    return this.http.get<ApprovalType[]>(this.getApprovalTypeUrl);
  }
}
