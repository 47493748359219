import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { GlobalWorkerOptions } from 'pdfjs-dist';

// Set the workerSrc property to the location of the pdf.worker.js file
GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.js'; // Adjust the path as per your project structure

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
