import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CompanyType, FilteredCompany, NoOfEmployees, RegisterFormData, SearchCompany, Sector, SubscriptionPlan } from '../models/register.model';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private getCompanyTypeUrl = `${environment.apiUrl}/api/1.0/getCompanyType`;
  private getCompanyHouse = `${environment.apiUrl}/api/sc/1.0/get_company_house`;
  private apiUrlSendVerification = `${environment.apiUrl}/api/1.0/sendVerificaionMessage`;
  private apiUrlValidateOTP = `${environment.apiUrl}/api/1.0/validateOTP`;
  private apiUrlGetVerificationType = `${environment.apiUrl}/api/1.0/getVerificationType`;
  private apiUrlGetVerificationStatusType = `${environment.apiUrl}/api/1.0/getVerificationStatusType`;
  private apiUrl = `${environment.apiUrl}/api/1.0/getSector`;
  private noOfEmployeesUrl = `${environment.apiUrl}/api/1.0/getNoofEmployees`;
  private apiUrl1 = `${environment.apiUrl}/api/1.0/createNewUserAsync`;
  private subscriptionUrl = `${environment.apiUrl}/api/1.0/getSubscriptionPlanInfo`;
  private randomKeyUrl = `${environment.apiUrl}/api/1.0/authVerifyRegisterRandomKeyAsync`;
  private validatePromoCodeUrl = `${environment.apiUrl}/api/1.0/validatePromoCodeAsync`;
  constructor(
    private http: HttpClient
  ) { }

  sendVerification(mobileNo: string, emailId: string, verificationType: number, verificationStatusType: number,  registrationNo: string | null): Observable<any> {
    const data = {
      mobileNo: mobileNo,
      emailId: emailId,
      verificationType: verificationType,
      verificationStatusType: verificationStatusType,
      registrationNo: registrationNo

    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.apiUrlSendVerification, data, httpOptions);
  }

  verifyOTP(mobileNo: string, emailId: string, otp: string, verificationType: number): Observable<any> {
    const data = {
      mobileNo: mobileNo,
      emailId: emailId,
      otp: otp,
      verificationType: verificationType
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.apiUrlValidateOTP, data, httpOptions);
  }

  getVerificationTypes(): Observable<any> {
    return this.http.get<any>(this.apiUrlGetVerificationType);
  }

  getVerificationStatusTypes(): Observable<any> {
    return this.http.get<any>(this.apiUrlGetVerificationStatusType);
  }

  async mapResponseKeys(response: any) {
    const verificationTypeResponse = await this.getVerificationTypes().toPromise();
    const verificationStatusTypeResponse = await this.getVerificationStatusTypes().toPromise();

    const verificationTypeMap = this.mapKeysToValues(verificationTypeResponse);
    const verificationStatusTypeMap = this.mapKeysToValues(verificationStatusTypeResponse);

    const mappedResponse = {
      mobileNo: response.mobileNo,
      emailId: response.emailId,
      verificationType: verificationTypeMap[response.verificationType],
      verificationStatusType: verificationStatusTypeMap[response.verificationStatusType]
    };

    return mappedResponse;
  }

  private mapKeysToValues(array: any[]): { [key: number]: string } {
    const mappedObject: { [key: number]: string } = {};
    array.forEach(item => {
      mappedObject[item.key] = item.value;
    });
    return mappedObject;
  }

  getCompanyTypes(): Observable<CompanyType[]> {
    return this.http.get<CompanyType[]>(this.getCompanyTypeUrl);
  }

  getCompanyData(searchQuery: string): Observable<SearchCompany[]> {
    return this.http.post<SearchCompany[]>(this.getCompanyHouse, { searchQuery });
  }

  getSectors(): Observable<Sector[]> {
    return this.http.get<Sector[]>(this.apiUrl);
  }

  getNoOfEmployees(): Observable<NoOfEmployees[]> {
    return this.http.get<NoOfEmployees[]>(this.noOfEmployeesUrl);
  }

  fetchCompanies(companyName: string): Observable<FilteredCompany[]> {
    return this.http.post<FilteredCompany[]>(`${environment.apiUrl}/api/sc/1.0/get_company_house`, { company_name: companyName });
  }

  createUser(formData: RegisterFormData): Observable<any> {
    return this.http.post<any>(this.apiUrl1, formData);
  }

  getSubscriptionPlans(promoCode?: string): Observable<SubscriptionPlan[]> {
    const url = promoCode 
      ? `${this.subscriptionUrl}?PromoCode=${promoCode}`
      : this.subscriptionUrl;
    return this.http.get<SubscriptionPlan[]>(url);
  }

  verifyRandomKey(randomKey: string): Observable<boolean> {
    const payload = { randomKey };
    return this.http.post<any>(this.randomKeyUrl, payload).pipe(
      map(response => response.status === 1 && response.message === 'RandomKey verification success')
    );
  }
  validatePromoCode(data: { promoCode: string }): Observable<any> {
    return this.http.post<any>(this.validatePromoCodeUrl, data);
  }
}
