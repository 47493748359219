<div class="card">
    <div class="card-header bg-light justify-content-between d-flex align-items-center">
        <div class="d-flex align-items-center">
            <svg height="25" width="10" style="margin-right: 5px">
              <line
                x1="5"
                y1="0"
                x2="5"
                y2="50"
                style="stroke: rgb(0, 0, 0); stroke-width: 4"
              />
            </svg>
            <p class="my-1 fs-6 fw-bolder">Tutorial Video</p>
        </div>
            <div class="d-flex align-items-center">
            <button
      class="close-icon fs-6 border-0 text-danger fw-bold bg-transparent"
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
</div>    
</div>
    <div class="card-body">
      <div mat-dialog-content>
        <iframe width="100%" height="350px" [src]="sanitizedUrl"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
  