import { Component, Inject, OnInit } from '@angular/core';
import { ActionType } from '../../models/action-type.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionTypeService } from '../../services/action-type.service';
import { DashboardChartService } from 'src/app/dashboard/dashboard/service/dashboard-chart.service';

@Component({
  selector: 'app-doc-verify-confirmation',
  templateUrl: './doc-verify-confirmation.component.html',
  styleUrls: ['./doc-verify-confirmation.component.scss'],
})
export class DocVerifyConfirmationComponent implements OnInit {
  actionTypes: ActionType[] = [];
  actionForm!: FormGroup;
  initialActionType!: number | null;
  isLoadingRequired: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<DocVerifyConfirmationComponent>,
    private actionTypeService: ActionTypeService,
    private fb: FormBuilder,
    private dashboardChartService: DashboardChartService,
    @Inject(MAT_DIALOG_DATA)
    public data: { actionTypes: ActionType[]; initialActionType: number | null }
  ) {}

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  ngOnInit(): void {
    this.actionForm = this.fb.group({
      selectedActionType: [this.data.initialActionType || ''],
      remarks: [''],
    });

    this.loadActionTypeData(this.data.initialActionType || 0);
  }

  loadActionTypeData(actionType: number): void {
    this.actionTypeService
      .getActionTypes(actionType)
      .subscribe((actionTypes) => {
        this.actionTypes = actionTypes;
      });
  }

  onSubmit() {
    if (this.actionForm.valid) {
      const selectedActionType = parseInt(
        this.actionForm.get('selectedActionType')?.value,
        10
      );
      const remarks = this.actionForm.get('remarks')?.value;
      if (!isNaN(selectedActionType)) {
        this.dialogRef.close({ selectedActionType, remarks });
        this.dashboardChartService.updatedEmployeeComp.emit();
      } else {
        console.error('Invalid action type selected.');
      }
    } else {
      console.error('Form is invalid.');
    }
  }
}
