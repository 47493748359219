import { NgModule } from '@angular/core';
import { LoadingAnimationComponent } from '../components/loading-animation/loading-animation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [LoadingAnimationComponent],
  exports: [LoadingAnimationComponent],
  imports: [MatProgressSpinnerModule],
})
export class LoadingAnimationModule {}
