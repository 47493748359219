import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { ApprovalType, LeaveApprovalList, LeaveApprovalType, UpdateLeaveApproval } from '../model/pending-request.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PendingRequestService {
  private baseUrl = environment.apiUrl;
  private getLeaveApprovalListApiUrl = `${this.baseUrl}/api/1.0/getLeaveApprovalListAsync`;
  private getApprovalTypeApiUrl = `${this.baseUrl}/api/1.0/getApprovalType`;
  private updateLeaveApprovalUrl = `${this.baseUrl}/api/1.0/updateLeaveApprovalAsync`;
  private getLeaveApprovalTypeUrl = `${this.baseUrl}/api/1.0/getLeaveApprovalTypeAsync`;

  constructor(private http: HttpClient) {}

  getLeaveApprovalList(
    companyInfoId: number,
    leaveDateInfoId: number,
    loginUserInfoId: number,
    loginRoleInfoId: number
  ): Observable<LeaveApprovalList[]> {
    return this.http.get<LeaveApprovalList[]>(
      `${this.getLeaveApprovalListApiUrl}?CompanyInfoId=${companyInfoId}&LeaveDateInfoId=${leaveDateInfoId}&LoginUserInfoId=${loginUserInfoId}&LoginRoleInfoId=${loginRoleInfoId}`
    );
  }

  getApprovalTypes(): Observable<ApprovalType[]> {
    return this.http.get<ApprovalType[]>(this.getApprovalTypeApiUrl);
  }

  updateLeaveApproval(leaveApproval: UpdateLeaveApproval): Observable<any> {
    return this.http.post<any>(this.updateLeaveApprovalUrl, leaveApproval);
  }

  getLeaveApprovalType(leaveTypeDetailInfoId: number): Observable<LeaveApprovalType[]> {
    return this.http.get<LeaveApprovalType[]>(
      `${this.getLeaveApprovalTypeUrl}?LeaveTypeDetailInfoId=${leaveTypeDetailInfoId}`
    );
  }
}
