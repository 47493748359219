import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appLowercaseInput]',
})
export class LowercaseInputDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input') onInput() {
    const value: string = this.el.nativeElement.value.toLowerCase();
    this.el.nativeElement.value = value;

    // Update the form control value
    this.control.control?.setValue(value);
  }
}
