import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';

export enum IdleUserTimes {
  IdleTime = 30 * 60 * 1000, // Time before showing the idle warning (30 minutes)
  // IdleTime = 10 * 1000, // Time before showing the idle warning (10 seconds)
  CountdownTime = 10 * 1000, // Time for the countdown (10 seconds)
}

@Injectable({
  providedIn: 'root',
})
export class IdleUserService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue!: number;
  private isIdle: boolean = false; // Flag to track idle state

  userInactive: Subject<boolean> = new Subject();
  countdown$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private router: Router) {
    this.reset();
    this.initListener();
  }

  initListener() {
    const resetEvents = [
      'mousemove',
      'click',
      'keypress',
      'DOMMouseScroll',
      'mousewheel',
      'touchmove',
      'MSPointerMove',
    ];
    resetEvents.forEach((event) =>
      window.addEventListener(event, () => this.onUserActivity())
    );
  }

  onUserActivity() {
    if (!this.isIdle) {
      this.reset(); // Reset timer only if user is not idle
    }
  }

  reset() {
    this.clearTimers(); // Clear any existing timers
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.isIdle = false; // Reset the idle flag
    this.timeoutId = setTimeout(() => {
      console.log('Inactivity detected');
      this.isIdle = true; // Set the idle flag when user is idle
      this.userInactive.next(true); // Notify component that user is idle
      this.startCountdown();
    }, IdleUserTimes.IdleTime);
  }

  startCountdown() {
    this.countdownValue = IdleUserTimes.CountdownTime / 1000;
    this.countdown$.next(this.countdownValue); // Update countdown value
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      this.countdown$.next(this.countdownValue); // Update countdown value
      console.log('You will logout in:', this.countdownValue, 'seconds');
      if (this.countdownValue <= 0) {
        this.logout(); // Logout the user
      }
    }, 1000);
  }

  logout() {
    this.clearTimers(); // Clear any running timers
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/auth']);
  }

  private clearTimers() {
    clearTimeout(this.timeoutId);
    clearInterval(this.countdownId);
  }
}
