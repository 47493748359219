import { Component, HostListener, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  animations: [
    trigger('headerAnimation', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'hidden',
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        })
      ),
      transition('visible <=> hidden', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class AuthLayoutComponent implements OnInit {
  isHeaderVisible: boolean = true;
  lastScrollTop = 0;
  delta = 5;
  navbarHeight = 56; // Change this value according to your header height

  buttonVisible = false;
  scrollProgress = 0;

  ngOnInit(): void {
    this.updateScrollProgress();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (Math.abs(this.lastScrollTop - st) <= this.delta) return;

    if (st > this.lastScrollTop && st > this.navbarHeight) {
      // Scroll down
      this.isHeaderVisible = false;
    } else {
      // Scroll up
      this.isHeaderVisible = true;
    }
    this.buttonVisible = st > 300; // Show button after scrolling 300px
    this.updateScrollProgress();
    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private updateScrollProgress() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    this.scrollProgress = (scrollTop / docHeight) * 100;
  }
}
