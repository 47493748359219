import { Component } from '@angular/core';
import { Router } from '@angular/router';
Router;

@Component({
  selector: 'app-subscription-alert',
  templateUrl: './subscription-alert.component.html',
  styleUrls: ['./subscription-alert.component.scss'],
})
export class SubscriptionAlertComponent {
  constructor(private router: Router) {}

  routeToSubscription() {
    this.router.navigate(['/content/subscription']);
  }
}
