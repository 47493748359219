import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSortCode]',
})
export class SortCodeDirective {
  constructor(@Optional() @Self() private ngControl: NgControl) {}

  //   @HostListener('input', ['$event']) onInput(event: Event) {
  //     const input = event.target as HTMLInputElement;
  //     let value: string = input.value;

  //     // Remove all non-numeric characters except hyphen
  //     value = value.replace(/[^0-9-]/g, '');

  //     // Format the value as XX-XX-XX
  //     value = value.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

  //     // Remove any extra hyphens not at the correct positions
  //     if (value.endsWith('-')) {
  //       value = value.slice(0, -1);
  //     }

  //     // Update the form control value if it exists
  //     if (this.ngControl) {
  //       this.ngControl.control?.setValue(value, { emitEvent: false });
  //     } else {
  //       // Update the input value directly if not using a form control
  //       input.value = value;
  //     }
  //   }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value: string = input.value;

    // Remove all non-numeric characters except hyphen
    value = value.replace(/[^0-9]/g, '');

    // Insert hyphen after every two digits
    if (value.length > 2) {
      value = value.match(/.{1,2}/g)?.join('-') || value;
    }

    // Prevent more than two hyphens
    if (value.length > 8) {
      value = value.substring(0, 8);
    }

    // Update the form control value if it exists
    if (this.ngControl) {
      this.ngControl.control?.setValue(value, { emitEvent: false });
    } else {
      // Update the input value directly if not using a form control
      input.value = value;
    }
  }
}
