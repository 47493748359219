<div class="blur-background">
  <div class="subscribe-popup">
    <p>Please subscribe to access the application</p>
    <button
      type="button"
      class="btn btn-sm btn-primary"
      (click)="routeToSubscription()"
    >
      Proceed to subscribe
    </button>
  </div>
</div>
