<app-subscription-alert *ngIf="!isSubscribed"></app-subscription-alert>
<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>
<div class="header_main">
  <section class="p-2">
    <div class="card">
      <div class="card-header header_dashboard d-flex align-items-center">
        <svg height="25" width="10" style="margin-right: 5px;">
          <line x1="5" y1="0" x2="5" y2="50" style="stroke:rgb(0,0,0);stroke-width:4" />
      </svg>
        <p class="my-1 fs-6 fw-bolder">Dashboard</p>
      </div>

      <div class="card-body p-0">
        <div class="row mx-0 header_company_employee_chart">
          <div class="col-xl-3 my-2 p-0">
            <app-company-chart></app-company-chart>
          </div>
          <div class="col-xl-5 my-2 px-2">
            <app-employee-chart></app-employee-chart>
          </div>
          <div class="col-xl-4 my-2 px-2">
            <app-company-count></app-company-count>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-2">
    <div class="card">
      <div class="card-header header_employee_count_expiry_main d-flex align-items-center">
        <svg height="25" width="10" style="margin-right: 5px;">
          <line x1="5" y1="0" x2="5" y2="50" style="stroke:rgb(0,0,0);stroke-width:4" />
      </svg>
        <p class="my-1 fs-6 fw-bolder">Employee Count & Document Expiry</p>
      </div>

      <div class="card-body p-0">
        <div class="row mx-0 header_employee_count_expiry_sub">
          <div class="col-xl-4 my-2 px-2">
            <app-employee-count></app-employee-count>
          </div>
          <div class="col-xl-8 my-2">
            <app-expiry-types></app-expiry-types>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="p-2">
    <div class="card">
      <div class="card-header section_header_company_expiry">
        <p class="my-1 fs-6 fw-bolder">Company Document Expiry</p>
      </div>

      <div class="card-body p-0">
        <div class="row mx-0 header_company_expiry">
          <div class="col-md-12 my-2">
            <app-company-expiry></app-company-expiry>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</div>
