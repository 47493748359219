import { Component } from '@angular/core';

@Component({
  selector: 'app-file-handler',
  templateUrl: './file-handler.component.html',
  styleUrls: ['./file-handler.component.scss'],
})
export class FileHandlerComponent {
  constructor() {}

  getIconUrl(fileExtensionType: string): string {
    switch (fileExtensionType) {
      case 'JPG':
        return '/assets/Icons/image.png';
      case 'PDF':
        return '/assets/Icons/pdf.png';
      case 'None':
        return '/assets/Icons/noimage4.png';
      case 'DOC':
        return '/assets/Icons/word.png';
      case 'DOCX':
        return '/assets/Icons/word.png';
      case 'TXT':
        return '/assets/Icons/txt.png';
      case 'PPT':
        return '/assets/Icons/ppt.png';
      case 'XLS':
      case 'XLSX':
      case 'CSV':
        return '/assets/Icons/excel2.png';
      case 'BMP':
      case 'GIF':
      case 'PNG':
        return '/assets/Icons/image.png';
      default:
        return '/assets/Icons/download.png';
    }
  }

  getMimeType(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'doc':
      case 'docx':
        return 'application/msword';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return 'application/octet-stream';
    }
  }

  getFileExtensionTypeByName(extensionName: string): number {
    switch (extensionName.toUpperCase()) {
      case 'JPG':
        return 1;
      case 'PNG':
        return 2;
      case 'GIF':
        return 3;
      case 'BMP':
        return 4;
      case 'PDF':
        return 5;
      case 'DOC':
        return 6;
      case 'DOCX':
        return 7;
      case 'TXT':
        return 8;
      case 'XLS':
        return 9;
      case 'XLSX':
        return 10;
      case 'CSV':
        return 11;
      case 'PPT':
        return 12;
      default:
        return 0; // Return 0 for unknown file types
    }
  }

  getMimeTypeByExtensionType(extensionType: number): string {
    switch (extensionType) {
      case 1:
        return 'image/jpeg';
      case 2:
        return 'image/png';
      case 3:
        return 'image/gif';
      case 4:
        return 'image/bmp';
      case 5:
        return 'application/pdf';
      case 6:
        return 'application/msword';
      case 7:
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 8:
        return 'text/plain';
      case 9:
        return 'application/vnd.ms-excel';
      case 10:
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 11:
        return 'text/csv';
      case 12:
        return 'application/vnd.ms-powerpoint';
      default:
        return 'application/octet-stream'; // Default MIME type
    }
  }

  getFileExtensionType(file: File | null): number {
    if (!file) {
      return 20;
    }
    const extension = file.name.split('.').pop()?.toUpperCase();
    switch (extension) {
      case 'JPG':
      case 'JPEG':
        return 1;
      case 'PNG':
        return 2;
      case 'GIF':
        return 3;
      case 'BMP':
        return 4;
      case 'PDF':
        return 5;
      case 'DOC':
        return 6;
      case 'DOCX':
        return 7;
      case 'TXT':
        return 8;
      case 'XLS':
        return 9;
      case 'XLSX':
        return 10;
      case 'CSV':
        return 11;
      case 'PPT':
        return 12;
      default:
        return 0; // Return 0 for unknown file types
    }
  }

  getFileExtensionTypeLowerCase(fileExtension: string): number {
    switch (fileExtension.toLowerCase()) {
      case 'pdf':
        return 1;
      case 'doc':
        return 2;
      case 'docx':
        return 3;
      case 'jpg':
        return 4;
      case 'png':
        return 5;
      case 'gif':
        return 6;
      case 'bmp':
        return 7;
      case 'txt':
        return 8;
      case 'xls':
        return 9;
      case 'xlsx':
        return 10;
      case 'csv':
        return 11;
      case 'ppt':
        return 12;
      default:
        return 0;
    }
  }

  getFileExtensionTypeDouble(fileExtension: string): number {
    switch (fileExtension.toLowerCase()) {
      case 'pdf':
        return 1;
      case 'doc':
      case 'docx':
        return 2;
      case 'jpg':
      case 'jpeg':
        return 3;
      case 'png':
        return 4;
      case 'xls':
      case 'xlsx':
        return 5;
      default:
        return 0;
    }
  }
}
