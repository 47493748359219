<div class="chart-container mt-2">
  <div class="custom-chart">
    <apx-chart
      *ngIf="chartOptions"
      [title]="chartOptions.title"
      [series]="chartOptions.series"
      [legend]="chartOptions.legend"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [colors]="chartOptions.colors"
      [responsive]="chartOptions.responsive"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </div>
</div>
