import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, geoMappingInfoId: number,  taskInfoId: number},
  ) {}

  confirmDelete(): void {
    this.dialogRef.close({ confirmed: true, geoMappingInfoId: this.data.geoMappingInfoId, taskInfoId: this.data.taskInfoId });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
