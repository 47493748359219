<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>

<div *ngIf="isUserIdle" class="idle-overlay">
  <div class="idle-overlay">
    <div class="idle-popup">
      <h2>You're Idle</h2>
      <p>
        You will be logged out in
        <span class="countdown">{{ countdownValue }}</span> seconds.
      </p>
      <button class="stay-online-btn" (click)="reset()">Stay Online</button>
    </div>
  </div>
</div>

<mat-toolbar class="mat-elevation-z8" [ngStyle]="{ 'background-color': roleId === 5 ? '#ffc107' : '#cee0f9' }">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened" class="text-secondary"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened" class="text-secondary"> close </mat-icon>
    <!-- <mat-icon *ngIf="sidenav.opened" class="text-secondary"> menu </mat-icon> -->
  </button>

  <div class="w-100">
    <div class="row">
      <div class="col-sm-6">
        <div class="d-flex align-items-center">
          <a class="header_img rounded-circle" href="#">
            <img class="rounded-circle" id="headerstaffphoto" src="../../../assets/Images/company-logo.png" />
          </a>

          <div class="d-inline-block">
            <div class="companyinfo_div">
              <strong>
                <span id="headercompanyname">{{ companyName }}</span></strong>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="d-flex align-items-center justify-content-end">
          <div class="me-3 bg-transparent">
            <app-notification></app-notification>
          </div>

          <div class="d-inline-block text-end">
            <div class="userinfo_div">
              <strong>
                <span id="headerstaffname">{{ firstName }} {{ lastName }}</span>
              </strong>
            </div>

            <div class="userrole_div">
              <small>
                <span id="headerstaffrole">{{ role }}</span></small>
            </div>
          </div>

          <div class="header_img2 rounded-circle">
            <ng-container *ngIf="base64; else defaultImage">
              <img [src]="base64" alt="Default Profile Image" id="headerstaffphoto" class="rounded-circle" />
            </ng-container>
            <ng-template #defaultImage>
              <img class="rounded-circle" id="headerstaffphoto" src="../../../assets/Images/profile-img.png"
                alt="Default Profile Image" />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8 sidenav-content" opened
    [ngStyle]="{ 'background-color': roleId === 5 ? '#373737ed' : '#265fc2' }">
    <div class="sidenav-content">
      <div class="sidenav-buttons">
        <button class="menu-button" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span class="menu-font-color">Sign out</span>
        </button>
        <mat-divider></mat-divider>
        <button class="menu-button" routerLink="dashboard" routerLinkActive="active-link"
          *ngIf="permissionService.hasPermission('dashboard', permissions)" (click)="accordianClose()">
          <mat-icon>dashboard</mat-icon>
          <span class="menu-font-color">Dashboard</span>
        </button>
        <button class="menu-button" routerLink="my-company" routerLinkActive="active-link" (click)="accordianClose()"
          *ngIf="permissionService.hasPermission('myCompany', permissions)">
          <mat-icon>business</mat-icon>
          <span class="menu-font-color">My Company</span>
        </button>
        <button class="menu-button" routerLink="my-user" routerLinkActive="active-link" (click)="accordianClose()"
          *ngIf="permissionService.hasPermission('myUser', permissions)">
          <mat-icon>verified_user</mat-icon>
          <span class="menu-font-color">My User</span>
        </button>

        <mat-divider *ngIf="roleId !== 5"></mat-divider>

        <button class="menu-button" routerLink="create-job" routerLinkActive="active-link" (click)="accordianClose()"
          *ngIf="permissionService.hasPermission('createJob', permissions)">
          <mat-icon>business_center</mat-icon>
          <span class="menu-font-color">Create Job</span>
        </button>
        <button class="menu-button" routerLink="employee-info" routerLinkActive="active-link" (click)="accordianClose()"
          *ngIf="permissionService.hasPermission('employeeInfo', permissions)">
          <mat-icon>supervisor_account</mat-icon>
          <span class="menu-font-color pe-2">Employee Info</span>

          <button class="border-0 bg-transparent btn_quick_add" matTooltip="Add Employee" (click)="onSelectEmployee()"
            style="margin-top: -2px" *ngIf="roleId !== 5">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white"
              class="bi bi-plus-square-fill text-white" viewBox="0 0 16 16">
              <path
                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0" />
            </svg>
          </button>
        </button>
        <button class="menu-button" routerLink="hiring-process" routerLinkActive="active-link"
          *ngIf="permissionService.hasPermission('hiringProcess', permissions)" (click)="accordianClose()">
          <mat-icon>work_outline</mat-icon>
          <span class="menu-font-color" routerLink="hiring-process">Hiring Process</span>
        </button>

        <mat-divider *ngIf="roleId !== 5"></mat-divider>
        <mat-accordion [multi]="false" #accordion="matAccordion">
          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" *ngIf="permissionService.hasPermission('hierarchy', permissions)" class="shadow-none menu-expansion">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">bar_chart</mat-icon>
                <span class="menu-font-color">Hierarchy</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/hierarchy-management/hierarchy-chart"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">bar_chart</mat-icon>
              <span class="menu-font-color">Hierarchy Chart</span>
            </button>
            <button class="menu-button" routerLink="/content/hierarchy-management/department"
              routerLinkActive="active-link" *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color">device_hub</mat-icon>
              <span class="menu-font-color">Department</span>
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion" *ngIf="
              permissionService.hasPermission('humanResource', permissions)
            ">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">portrait</mat-icon>
                <span class="menu-font-color">HR - Classic </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/hr/rota" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">history</mat-icon>
              <span class="menu-font-color">Rota - Classic </span>
            </button>
            <button class="menu-button" routerLink="/content/hr/payslip" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">event_note</mat-icon>
              <span class="menu-font-color">Payslip - Classic</span>
            </button>
            <button class="menu-button" routerLink="/content/hr/leave" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">note</mat-icon>
              <span class="menu-font-color">Leave - Classic</span>
            </button>
          </mat-expansion-panel>

          <mat-divider></mat-divider>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion" *ngIf="
              permissionService.hasPermission('rotaManagement', permissions)
            ">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">gps_fixed</mat-icon>
                <span class="menu-font-color">TimeSeel - Geo Attendance</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/raksa/raksa" routerLinkActive="active-link"
              *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color"> add_location</mat-icon>
              <span class="menu-font-color">Add Location</span>
            </button>
            <button class="menu-button" routerLink="/content/raksa/mapping" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">business_center</mat-icon>
              <span class="menu-font-color">Shift Assign</span>
            </button>
            <button class="menu-button" routerLink="/content/raksa/qrcode" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">qr_code</mat-icon>
              <span class="menu-font-color">QR Code</span>
            </button>
            <button class="menu-button" routerLink="/content/raksa/report" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">list_alt</mat-icon>
              <span class="menu-font-color">Report</span>
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion" *ngIf="
              permissionService.hasPermission('rotaManagement', permissions)
            ">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">history</mat-icon>
                <span class="menu-font-color">TimeSeel - Rota</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button  class="menu-button" routerLink="/content/rota-management/rota-overview"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">assessment</mat-icon>
              <span class="menu-font-color">Rota - Overview</span>
            </button>
            <button class="menu-button" routerLink="/content/rota-management/create-rota" routerLinkActive="active-link"
              *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">note_add</mat-icon>
              <span class="menu-font-color">Create Rota</span>
            </button>
            <button class="menu-button" routerLink="/content/rota-management/rota-list" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">assignment</mat-icon>
              <span class="menu-font-color">Rota List</span>
            </button>
            <button class="menu-button" routerLink="/content/rota-management/employeerota-details"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">person</mat-icon>
              <span class="menu-font-color">Employee Rota</span>
            </button>
            <button class="menu-button" routerLink="/content/rota-management/add-location"
              routerLinkActive="active-link" *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">gps_fixed</mat-icon>
              <span class="menu-font-color">Add Location</span>
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion" *ngIf="
              permissionService.hasPermission('taskManagement', permissions)
            ">
            <mat-expansion-panel-header [ngClass]="{ 'active-link': isTaskDetailsRoute() }">
              <mat-panel-title>
                <mat-icon class="mat_icon_fontsize"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">bookmark</mat-icon>
                <span class="menu-font-color">TimeSeel - Task </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/task-management/task-list" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">assignment</mat-icon>
              <ng-container *ngIf="
                  (roleId !== 5 &&
                    permissionService.hasPermission(
                      'createTask',
                      permissions
                    )) ||
                    (createTask === 1 && roleId === 5);
                  else rotaListMessage
                ">
                <span class="menu-font-color">Create & List</span>
              </ng-container>
              <ng-template #rotaListMessage>
                <span class="menu-font-color">Task List</span>
              </ng-template>
            </button>

            <button class="menu-button" routerLink="/content/task-management/action-details"
              routerLinkActive="active-link" *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color">settings</mat-icon>
              <span class="menu-font-color">Action Settings</span>
            </button>
          </mat-expansion-panel>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion" *ngIf="
              permissionService.hasPermission('rotaManagement', permissions)
            ">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">history</mat-icon>
                <span class="menu-font-color">TimeSeel - Leave</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/leave-management/calendar" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">calendar_today</mat-icon>
              <span class="menu-font-color">Calendar</span>
            </button>
            <button class="menu-button" routerLink="/content/leave-management/employee-leave-list"
              routerLinkActive="active-link" *ngIf="roleId !== 1">
              <mat-icon class="menu_icon_color">assignment</mat-icon>
              <span class="menu-font-color">Leave List</span>
            </button>
            <button class="menu-button" routerLink="/content/leave-management/leave-allocation"
              routerLinkActive="active-link" *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">event_available</mat-icon>
              <span class="menu-font-color">Leave Allocation</span>
            </button>
            <!-- <button
              class="menu-button"
              routerLink="/content/leave-management/leave-summary"
              routerLinkActive="active-link"
            >
              <mat-icon class="menu_icon_color">assignment</mat-icon>
              <span class="menu-font-color">Leave Summary</span>
            </button> -->
            <!-- <button
              class="menu-button"
              routerLink="/content/leave-management/leave-list"
              routerLinkActive="active-link"
              *ngIf="roleId !== 5"
            >
              <mat-icon class="menu_icon_color">list_alt</mat-icon>
              <span class="menu-font-color">Leave List</span>
            </button> -->

            <button class="menu-button" routerLink="/content/leave-management/pending-request"
              routerLinkActive="active-link" *ngIf="leaveApprovalList.length;">
              <mat-icon class="menu_icon_color">list_alt</mat-icon>
              <span class="menu-font-color">Pending Request</span>
            </button>

            <button class="menu-button" routerLink="/content/leave-management/leave-list"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">list_alt</mat-icon>
              <span class="menu-font-color">Leave Type</span>
            </button>

            <!-- <button
              class="menu-button"
              routerLink="/content/leave-management/holiday-list"
              routerLinkActive="active-link"
            >
              <mat-icon
                class="menu_icon_color"
                style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem"
                >playlist_add</mat-icon
              >
              <span class="menu-font-color">Holiday List</span>
            </button> -->
            <!-- <button
            class="menu-button"
            routerLink="/content/leave-management/employee-leaves"
            routerLinkActive="active-link"
          >
            <mat-icon
              class="menu_icon_color"
              style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem"
              >supervisor_account</mat-icon
            >
            <span class="menu-font-color">Employee Leaves</span>
          </button> -->
          </mat-expansion-panel>
          <button class="menu-button" routerLink="group-chat" routerLinkActive="active-link"
            (click)="accordianClose()">
            <mat-icon> forum</mat-icon>
            <span class="menu-font-color">TimeSeel - Chat</span>
          </button>
          <mat-divider></mat-divider>

          <button class="menu-button" routerLink="subscription" routerLinkActive="active-link"
            (click)="accordianClose()" *ngIf="permissionService.hasPermission('subscription', permissions)">
            <mat-icon>person</mat-icon>
            <span class="menu-font-color">Subscription</span>
          </button>

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" class="shadow-none menu-expansion">
            <mat-expansion-panel-header class="pe-3">
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">person_add</mat-icon>
                <span class="menu-font-color">Refer & Earn</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button mat-button class="menu-button" routerLink="/content/refer-earn/create-ticket"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">note_add</mat-icon>
              <span class="menu-font-color">Create Ticket</span>
            </button>
            <button mat-button class="menu-button" routerLink="/content/refer-earn/referral-list"
              routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">assignment</mat-icon>
              <span class="menu-font-color">Referral List</span>
            </button>
          </mat-expansion-panel>
          <button class="menu-button" routerLink="faq" routerLinkActive="active-link" (click)="accordianClose()">
            <mat-icon>question_answer</mat-icon>
            <span class="menu-font-color">FAQ's & Tutorials</span>
          </button>

          <!-- <button
          mat-button
          class="menu-button"
          [routerLink]="
            roleId === 5
              ? '/content/settings/update-password'
              : '/content/settings'
          "
          routerLinkActive="active-link"
          *ngIf="permissionService.hasPermission('settings', permissions)"
        >
          <mat-icon>settings</mat-icon>
          <span class="menu-font-color">Settings</span>
        </button> -->

          <mat-expansion-panel [ngStyle]="{
              'background-color': roleId === 5 ? '#454545' : '#265fc2'
            }" *ngIf="permissionService.hasPermission('settings', permissions)" class="shadow-none menu-expansion">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="menu_icon_color"
                  style="font-size: 1.125rem; height: 1.125rem; width: 1.125rem">settings</mat-icon>
                <span class="menu-font-color">Settings</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-button" routerLink="/content/settings/permissions" routerLinkActive="active-link"
              *ngIf="permissionService.hasPermission('permission', permissions)">
              <mat-icon class="menu_icon_color">toggle_on</mat-icon>
              <span class="menu-font-color">Permissions</span>
            </button>
            <button class="menu-button" routerLink="/content/settings/notification" routerLinkActive="active-link"
              *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color">notifications</mat-icon>
              <span class="menu-font-color">Notification</span>
            </button>
            <button class="menu-button" routerLink="/content/settings/update-password" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">vpn_key</mat-icon>
              <span class="menu-font-color">Update Password</span>
            </button>

            <button mat-button class="menu-button" routerLink="/content/settings/qr-code" routerLinkActive="active-link"
              *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color">qr_code</mat-icon>
              <span class="menu-font-color">QR Code</span>
            </button>
            <button class="menu-button" routerLink="/content/settings/ticket" routerLinkActive="active-link"
              *ngIf="roleId !== 5">
              <mat-icon class="menu_icon_color">confirmation_number</mat-icon>
              <span class="menu-font-color">Ticket</span>
            </button>
            <button class="menu-button" routerLink="/content/settings/updates" routerLinkActive="active-link">
              <mat-icon class="menu_icon_color">system_update</mat-icon>
              <span class="menu-font-color">Software Updates</span>
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="powered-by">
        <p class="mb-0" style="margin-left: 14px; font-size: 10.5px">
          Powered by
        </p>

        <a href="https://britappsystems.com/pages/home" target="_blank" style="margin-left: -10px"><img
            src="../../../assets/Images/Britapps_Logo_Transparent.png" alt="Britappsystems Logo"
            title="Britappsystems Logo" style="width: 100px; margin-top: -3px" /></a>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <div class="child-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>