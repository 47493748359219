<div class="page-container">
  <div class="content-wrap">
    <div
      [@headerAnimation]="isHeaderVisible ? 'visible' : 'hidden'"
      class="header"
    >
      <nav id="navbar_top" class="navbar navbar-expand-lg autohide">
        <div class="container">
          <a class="navbar-brand" routerLink="Home">
            <img
              class="nav_logo"
              src="../../assets/Images/ESMACS_Logo_Transparent.png"
              alt="ESMACS Logo"
              title="ESMACS Logo"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main_nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item on_home">
                <a
                  class="nav-link"
                  routerLink="/pages/home"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Home
                </a>
              </li>
              <li class="nav-item on_product">
                <a
                  class="nav-link"
                  routerLink="/pages/product"
                  [routerLinkActive]="'active'"
                  >Product
                </a>
              </li>
              <!-- <li class="nav-item on_pricing">
                <a class="nav-link" style="cursor: pointer" routerLink="pricing">Pricing
                </a>
              </li> -->
              <li class="nav-item on_aboutus">
                <a
                  class="nav-link"
                  routerLink="/pages/about-us"
                  [routerLinkActive]="'active'"
                  >About Us
                </a>
              </li>
              <li class="nav-item on_contactus">
                <a
                  class="nav-link"
                  routerLink="/pages/contact-us"
                  [routerLinkActive]="'active'"
                  >Contact Us
                </a>
              </li>
              <li>
                <a
                  id="btn_login"
                  class="btn btn-sm btn-primary shadow"
                  routerLink="/auth/login"
                  >Login</a
                >
              </li>
              <!-- <li>
                <a
                  id="btn_register"
                  class="btn btn-sm btn-primary shadow"
                  href="https://support.esmacs.com/pages/demo"
                  >Free Trial</a
                >
              </li> -->
            </ul>
          </div>
          <!-- navbar-collapse.// -->
        </div>
        <!-- container-fluid.// -->
      </nav>
    </div>

    <div style="margin-top: 82px">
      <router-outlet></router-outlet>
    </div>

    <!-- Back to Top Button and Scroll Progress Indicator -->
    <div class="scroll-progress" [style.width.%]="scrollProgress"></div>
    <div
      class="back-to-top"
      (click)="scrollToTop()"
      [style.opacity]="buttonVisible ? '1' : '0'"
    >
      ↑
    </div>
  </div>
  <footer class="text-center text-lg-start text-dark">
    <div class="container text-center text-md-start pt-3">
      <div class="row mt-3">
        <div class="col-md-4 mx-auto mb-3">
          <h3 class="text-uppercase fw-bold company_title">Esmacs</h3>
          <h4 style="margin-bottom: 3px">Trusted Partner in UKVI Compliance</h4>
          <hr
            class="mt-0 d-inline-block mx-auto"
            style="width: 282px; background-color: #7c4dff; height: 2px"
          />

          <div>
            <img
              id="img_ico"
              src="../../assets/Images/ICO_Icon.png"
              alt="Information Commissioner's Office"
              title="Information Commissioner's Office"
            />
          </div>

          <p
            id="registration"
            style="margin-top: 6px; color: #003768; font-size: 15px"
          >
            Registration No: ZB582609
          </p>
        </div>

        <div class="col-md-1"></div>

        <div class="col-md-4 mx-auto mb-3">
          <h3 class="text-uppercase fw-bold mb-0">Our Services</h3>
          <hr
            class="mt-0 d-inline-block mx-auto"
            style="width: 110px; background-color: #7c4dff; height: 2px"
          />

          <ul style="padding-left: 0px; list-style-type: none">
            <li>
              <span style="font-weight: 600; margin-left: -6px"
                ><i class="fa-li fa fa-check"></i>Document Management</span
              >
            </li>

            <li>
              <span style="font-weight: 600; margin-left: -6px"
                ><i class="fa-li fa fa-check"></i>Compliance Management</span
              >
            </li>

            <li>
              <span style="font-weight: 600; margin-left: -6px"
                ><i class="fa-li fa fa-check"></i>Right to Work Check</span
              >
            </li>
          </ul>
        </div>

        <div class="col-md-2 mx-auto mb-3" style="display: none">
          <h3 class="text-uppercase fw-bold mb-0">Useful links</h3>
          <hr
            class="mt-0 d-inline-block mx-auto"
            style="width: 106px; background-color: #7c4dff; height: 2px"
          />
          <p>
            <a href="../default.aspx" class="text-dark">Home</a>
          </p>
          <p>
            <a href="../product.aspx" class="text-dark">Product</a>
          </p>
          <p>
            <a href="../aboutus.aspx" class="text-dark">About Us</a>
          </p>
          <p>
            <a href="../contactus.aspx" class="text-dark">Contact Us</a>
          </p>
        </div>

        <div class="col-md-3 mb-md-0 mb-3">
          <h3 class="text-uppercase fw-bold mb-0">Contact</h3>
          <hr
            class="mt-0 d-inline-block mx-auto"
            style="width: 73px; background-color: #7c4dff; height: 2px"
          />

          <p><i class="fas fa-phone"></i>&nbsp;020 8058 8383</p>
          <p><i class="fas fa-envelope"></i>&nbsp;support&#64;esmacs.com</p>
          <p><i class="fas fa-home"></i>&nbsp;37th Floor 1 Canada Square,</p>
          <p>
            <i class="fas fa-home" style="visibility: hidden"></i>&nbsp;London,
            England, E14 5AA
          </p>
          <p style="display: none">
            <i class="fas fa-print"></i>&nbsp;+ 01 234 567 89
          </p>
        </div>
      </div>
    </div>

    <div
      class="container-fluid p-0"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="py-3" style="color: white; font-size: 15px">
              © 2024 Copyright - All Rights Reserved.<br />
              Powered by Britapp Systems Ltd
            </div>
          </div>
          <div class="col-md-5">
            <div class="p-3" style="color: white; font-size: 15px">
              <a
                class="text-white"
                href="/pages/terms&conditions"
                target="_blank"
                >Terms & Conditions</a
              >
              |
              <a class="text-white" href="/pages/privacy-policy" target="_blank"
                >Privacy Policy</a
              >
              |
              <a class="text-white" href="/pages/cookies-policy" target="_blank"
                >Cookies Policy</a
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="p-3" style="text-align: right">
              <a
                href="https://www.facebook.com/profile.php?id=61551820912245"
                target="_blank"
                class="text-white me-4"
                style="text-decoration: none"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/ESMACS_UKVI"
                target="_blank"
                class="text-white me-4"
                style="text-decoration: none"
              >
                <i class="fab fa-x-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/esmacs_ukvi/"
                target="_blank"
                class="text-white me-4"
                style="text-decoration: none"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@ESMACS-UK"
                class="text-white me-4"
                style="text-decoration: none"
              >
                <i class="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
