<div class="card-header bg-light mx-3 my-2">
  <div class="row">
    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 px-1">
      <div class="card">
        <div class="card-body" style="padding: 6px; background: #cee0f9">
          <p class="my-1 fs-6 fw-bolder text-center text-truncate">
            {{ selectedEmployeeFirstName }} {{ selectedEmployeeLastName }}
            <span
              *ngIf="
                selectedEmployeeCode !== null && selectedEmployeeCode !== 'null'
              "
            >
              ({{ selectedEmployeeCode }})
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-7 col-md-6 col-sm-6 px-1 bg-light">
      <app-employee-comp></app-employee-comp>
    </div>
  </div>
</div>

<div class="card mx-2 my-2" *ngIf="documents.length > 0" style="height: 41px">
  <div class="d-flex" style="height: 100%">
    <p
      class="fs-6 fw-bolder d-flex justify-content-center align-items-center"
      style="
        min-width: 180px;
        height: 100%;
        border-right: 1px solid lightgray;
        background-color: lightgray;
      "
    >
      Pending Documents
    </p>
    <div class="pending_documents">
      <span *ngFor="let doc of orderedDocuments" class="">
        <span
          class="badge"
          [ngClass]="getBadgeClass(doc.mandatoryType)"
          style="font-size: 11px; font-weight: 400; margin: 0px 0px 2px 4px"
        >
          {{ doc.documentInfoName }}
        </span>
      </span>
    </div>
  </div>
</div>

<mat-drawer-container
  class="example-container"
  autosize
  [ngClass]="{ 'drawer-open': isDrawerOpen, 'drawer-closed': !isDrawerOpen }"
  [ngStyle]="{
    height: documents.length > 0 ? 'calc(100vh - 172px)' : 'calc(100vh - 124px)'
  }"
>
  <div class="example-sidenav-content">
    <button
      type="button"
      class="btn btn-light text-secondary btn_employee_menu_drawer border-0 shadow"
      (click)="drawer.toggle(); toggleDrawer()"
    >
      <mat-icon class="icon_employee_menu_drawer">{{
        isDrawerOpen ? "last_page" : "first_page"
      }}</mat-icon>
    </button>
    <div class="w-100 px-2">
      <router-outlet></router-outlet>
    </div>
  </div>

  <mat-drawer
    #drawer
    class="example-sidenav"
    mode="side"
    position="end"
    [opened]="isDrawerOpen"
  >
    <div *ngIf="showFiller" class="employee_menu_container">
      <mat-list role="list" class="p-0">
        <ng-container *ngFor="let complianceSequence of complianceSequences">
          <div
            class="d-flex align-items-center"
            *ngFor="let sequence of complianceSequence.sequenceType"
          >
            <!-- Use the getRouterLink method to dynamically generate the router link -->
            <mat-list-item
              class="p-2"
              role="listitem"
              [routerLink]="
                sequence.sequenceType !== 2
                  ? getRouterLink(sequence.compSequenceTypeName).link
                  : null
              "
              routerLinkActive="active-link"
              [disabled]="sequence.sequenceType === 2"
              *ngIf="
                sequence.compSequenceTypeName !== 'Hierarchy Chart' ||
                permissionService.hasPermission('hierarchy', permissions)
              "
            >
              <div style="display: flex; justify-content: space-between">
                <span class="compSequenceTypeName"
                  ><mat-icon class="menu_icon_color">{{
                    getRouterLink(sequence.compSequenceTypeName).icon
                  }}</mat-icon
                  >&nbsp;&nbsp;{{ sequence.compSequenceTypeName
                  }}<mat-icon *ngIf="sequence.starType === 1" class="star-icon"
                    >star</mat-icon
                  ></span
                >

                <span
                  *ngIf="sequence.compSequenceTypeName === 'None'"
                  role="listitem"
                  >{{ sequence.compSequenceTypeName }}</span
                >

                <span>
                  <i *ngIf="sequence.alarmSequenceType === 5">
                    <img
                      src="../../../assets/Images/bell.gif"
                      alt="Check Circle"
                      class="custom-icon"
                    />
                  </i>
                  <i *ngIf="sequence.sequenceType === 1"
                    ><mat-icon class="text-danger">error</mat-icon></i
                  >

                  <i *ngIf="sequence.sequenceType === 2"
                    ><mat-icon class="text-secondary">lock</mat-icon></i
                  >

                  <i *ngIf="sequence.sequenceType === 3"
                    ><mat-icon class="text-success">check_circle</mat-icon></i
                  >

                  <i *ngIf="sequence.sequenceType === 4"
                    ><mat-icon class="pe-1 text-success">check_circle</mat-icon
                    ><mat-icon class="text-success">check_circle</mat-icon></i
                  >
                </span>
              </div>
            </mat-list-item>
          </div>
        </ng-container>
        <mat-list-item
          class="p-2 list_item_history"
          routerLink="history"
          routerLinkActive="active-link"
          *ngIf="roleId !== 5"
        >
          <span class="compSequenceTypeName">
            <mat-icon class="menu_icon_color">history</mat-icon>
            &nbsp;&nbsp;History
          </span>
        </mat-list-item>

        <mat-list-item
          *ngIf="roleId !== 5"
          class="p-2 list_item_end_of_employement"
          routerLink="end-of-employement"
          routerLinkActive="active-link"
          ><span class="compSequenceTypeName"
            ><mat-icon class="menu_icon_color">event_busy</mat-icon
            >&nbsp;&nbsp;End of Employement</span
          ></mat-list-item
        >
      </mat-list>
    </div>
  </mat-drawer>
</mat-drawer-container>
