import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appLimitedSpecialCharactersInput]',
})
export class LimitedSpecialCharactersInputDirective {
  private allowedCharacters = /[^a-zA-Z0-9\/,.\\@!#&)(:\-_=,"* ]/g;

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private toastr: ToastrService
  ) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value: string = input.value;
    const initialLength = value.length;

    // Remove all characters except letters and allowed special characters
    value = value.replace(this.allowedCharacters, '');

    // Show toast if unsupported characters were removed
    if (value.length < initialLength) {
      this.toastr.error('Allow special characters are /\\@!#&)(:-_=,."*');
    }

    // Update the form control value if it exists
    if (this.ngControl) {
      this.ngControl.control?.setValue(value, { emitEvent: false });
    } else {
      // Update the input value directly if not using a form control
      input.value = value;
    }
  }
}
