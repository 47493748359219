import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environment/environment';
import {
  Employee,
  EmployeeList,
  EmployeePersonalInfo,
  EmployeeUpdate,
  ImmigrationTitle,
  ResendInviteRequest,
  SequenceType,
  getEmployeeType,
} from '../models/employee.model';
import { TokenService } from './jwt.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private baseApiUrl = environment.apiUrl;
  private createNewEmployeeAsyncUrl = `${this.baseApiUrl}/api/1.0/createNewEmployeeAsync`;
  private getImmigrationDataApiUrl = `${this.baseApiUrl}/api/1.0/getImmigrationDataAsync`;
  private selectJobPostandBatchAsyncApiUrl = `${this.baseApiUrl}/api/1.0/selectJobPostandBatchAsync`;
  private getEmployeeListAsyncApiUrl = `${this.baseApiUrl}/api/1.0/getEmployeeListAsync`;
  private getEuEeaNationalsTypeApiUrl = `${this.baseApiUrl}/api/1.0/getEuEeaNationalsType`;
  private getGenderTypeApiUrl = `${this.baseApiUrl}/api/1.0/getGenderType`;
  private getEngagementTypeApiUrl = `${this.baseApiUrl}/api/1.0/getEngagementType`;
  private getSponsorWorkerTypeApiUrl = `${this.baseApiUrl}/api/1.0/getSponsorWorkerType`;
  private getLoginAccessTypeApiUrl = `${this.baseApiUrl}/api/1.0/getLoginAccessType`;
  private getCountryInfoApiUrl = `${this.baseApiUrl}/api/1.0/getCountryInfo`;
  private getS3DocumentAsyncUrl = `${this.baseApiUrl}/api/1.0/getS3DocumentAsync`;
  private getEmployeeTypeApiUrl = `${this.baseApiUrl}/api/1.0/getEmployeeType`;
  private resendInviteApiUrl = `${this.baseApiUrl}/api/1.0/resendInviteAsync`;
  private deleteEmployeePersonalInfoApiUrl = `${this.baseApiUrl}/api/1.0/deleteEmployeePersonalInfoAsync`;
  private updateEmployeePersonalInfoApiUrl = `${this.baseApiUrl}/api/1.0/updateEmployeePersonalInfoAsync`;
  private getSequenceTypeApiUrl = `${this.baseApiUrl}/api/1.0/getSequenceType`;
  private getEmployeePersonalInfoApiUrl = `${this.baseApiUrl}/api/1.0/getEmployeePersonalInfoAsync`;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getEmployeeInfo(): Observable<ImmigrationTitle[]> {
    return this.http.get<ImmigrationTitle[]>(this.getImmigrationDataApiUrl);
  }

  searchJobBatches(companyInfoId: number, term: string): Observable<any[]> {
    const url = `${this.selectJobPostandBatchAsyncApiUrl}?CompanyInfoId=${companyInfoId}&CombinationSearch=${term}`;
    return this.http.get<any[]>(url);
  }

  getEmployeeList(companyInfoId: number): Observable<EmployeeList[]> {
    let url = `${this.getEmployeeListAsyncApiUrl}?CompanyInfoId=${companyInfoId}`;

    // Check if the user's role is 5 (Employee)
    const roleId = this.tokenService.getRoleInfoIdFromToken();
    if (roleId === 5) {
      // Get EmployeeId from localStorage
      const employeeId = localStorage.getItem('selectedEmployeeId');
      if (employeeId) {
        url += `&EmployeeId=${employeeId}`;
      } else {
        console.error('EmployeeId not found in localStorage');
      }
    }

    return this.http.get<EmployeeList[]>(url);
  }

  getEUNationalsTypeOptions(): Observable<any[]> {
    return this.http.get<any[]>(this.getEuEeaNationalsTypeApiUrl);
  }

  getGenderTypeOptions(): Observable<any[]> {
    return this.http.get<any[]>(this.getGenderTypeApiUrl);
  }

  getEngagementTypeOptions(): Observable<any[]> {
    return this.http.get<any[]>(this.getEngagementTypeApiUrl);
  }

  getSponsorTypeOptions(): Observable<any[]> {
    return this.http.get<any[]>(this.getSponsorWorkerTypeApiUrl);
  }

  getLoginAccessTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getLoginAccessTypeApiUrl);
  }

  getCountryTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getCountryInfoApiUrl);
  }

  getS3DocumentAsync(photoDocCode: string): Observable<any> {
    const url = `${this.getS3DocumentAsyncUrl}?DocCode=${photoDocCode}`;
    return this.http.get<any>(url);
  }

  createNewEmployee(employeeData: any): Observable<Employee[]> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');

    return this.http
      .post<Employee[]>(this.createNewEmployeeAsyncUrl, employeeData, {
        headers,
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          throw error;
        })
      );
  }

  getEmployeeType(): Observable<getEmployeeType[]> {
    return this.http.get<getEmployeeType[]>(this.getEmployeeTypeApiUrl);
  }

  getEmployeePersonalInfo(): Observable<EmployeePersonalInfo[]> {
    // Retrieve company info ID and employee ID
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const employeeId = this.getSelectedEmployeeIdFromLocalStorage();

    const url = `${this.getEmployeePersonalInfoApiUrl}?CompanyInfoId=${companyInfoId}&EmployeeId=${employeeId}`;
    return this.http.get<EmployeePersonalInfo[]>(url);
  }

  private getSelectedEmployeeIdFromLocalStorage(): number {
    const storedEmployeeId = localStorage.getItem('selectedEmployeeId');
    return storedEmployeeId ? +storedEmployeeId : 0;
  }

  updateEmployeeInfo(payload: EmployeeUpdate): Observable<any> {
    return this.http.post(this.updateEmployeePersonalInfoApiUrl, payload);
  }

  resendInvite(request: ResendInviteRequest): Observable<ResendInviteRequest> {
    return this.http.post<ResendInviteRequest>(
      this.resendInviteApiUrl,
      request
    );
  }

  deleteEmployeePersonalInfo(
    companyInfoId: number,
    employeeId: number
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');

    const payload = { companyInfoId, employeeId };

    return this.http
      .post(this.deleteEmployeePersonalInfoApiUrl, payload, { headers })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          throw error;
        })
      );
  }

  getSequenceTypes(): Observable<SequenceType[]> {
    return this.http.get<SequenceType[]>(this.getSequenceTypeApiUrl);
  }
}
