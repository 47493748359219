import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCapitializeTextSpaceInput]',
})
export class CapitializeTextSpaceInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input') onInput() {
    let value: string = this.el.nativeElement.value;

    // Filter the value to allow only letters and spaces
    value = value.replace(/[^a-zA-Z\s]/g, '');

    // Replace multiple spaces with a single space
    value = value.replace(/\s+/g, ' ');

    // Capitalize the first letter if the value is not empty
    if (value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    // Update the input value
    this.el.nativeElement.value = value;
  }
}
