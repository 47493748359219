import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCapitalizeWords]'
})
export class CapitalizeWordsDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = this.el.nativeElement;
    
    // Preserve the cursor position to avoid moving the cursor
    const start = input.selectionStart;
    
    // Capitalize first letter of each word (after spaces)
    input.value = this.capitalizeWords(input.value);

    // Restore the cursor position
    input.setSelectionRange(start, start);
  }

  capitalizeWords(value: string): string {
    return value
      .split(' ')  // Split by space
      .map((word: string) => word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '')  // Capitalize first letter
      .join(' ');  // Join words back with spaces
  }
}
