import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environment';
import {
  DeleteTaskRemarks,
  EditTaskRemarks,
  Task,
  TaskActionType,
  TaskInfoOverview,
  UpdateDueDate,
  UpdateEnquiryTaskStatusRequest,
  UpdateTaskRemarks,
} from '../model/task-history.model';
import { TaskDetail } from '../model/task.timeline.model';
import { TokenService } from 'src/app/shared/services/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class TaskDetailService {
  private baseUrl = `${environment.apiUrl}/api/1.0/`;
  private getTaskInfoTimeLineApiUrl = `${environment.apiUrl}/api/1.0/getTaskInfoTimeLineAsync`;
  private getTaskHistoryApiUrl = `${environment.apiUrl}/api/1.0/getTaskInfoAsync`;
  private updateTaskRemarksApiUrl = `${environment.apiUrl}/api/1.0/updateTaskRemarksAsync`;
  private getEnquiryTaskActionUrl = `${environment.apiUrl}/api/1.0/getTaskInfoActionTypeAsync`;
  private updateTaskDueDateUrl = `${environment.apiUrl}/api/1.0/updateTaskDueDateAsync`;
  private getTaskInfoOverviewUrl = `${environment.apiUrl}/api/1.0/getTaskInfoOverviewAsync`;

  updatedDueDate: EventEmitter<void> = new EventEmitter<void>();
  updatedTaskTimeline: EventEmitter<void> = new EventEmitter<void>();
  updatedCount: EventEmitter<void> = new EventEmitter<void>();
  updatedHistoryCount: EventEmitter<void> = new EventEmitter<void>();
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getActionEnquiryTypes(): Observable<TaskActionType[]> {
    const moduleType = 2;
    const enquiryTaskInfoId = localStorage.getItem('taskInfoId') || '0';
    const url = `${this.getEnquiryTaskActionUrl}?ModuleType=${moduleType}&TaskInfoId=${enquiryTaskInfoId}`;

    return this.http.get<TaskActionType[]>(url);
  }

  getTaskInfoTimeline(
    taskInfoId: number,
    companyInfoId: number
  ): Observable<TaskDetail[]> {
    const headers = new HttpHeaders({
      Accept: 'text/plain',
    });

    const url = `${this.getTaskInfoTimeLineApiUrl}?CompanyInfoId=${companyInfoId}&TaskInfoId=${taskInfoId}`;
    return this.http.get<TaskDetail[]>(url, { headers });
  }

  getTask(
    companyInfoId: number,
    taskInfoId: number,
    userOrEmployeeId: number
  ): Observable<Task[]> {
    const url = `${this.getTaskHistoryApiUrl}?CompanyInfoId=${companyInfoId}&TaskInfoId=${taskInfoId}&UserOrEmployeeId=${userOrEmployeeId}`;
    return this.http.get<Task[]>(url);
  }

  updateTaskRemarks(updateTaskRemarks: UpdateTaskRemarks): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'text/plain',
    });

    return this.http.post<any>(
      this.updateTaskRemarksApiUrl,
      updateTaskRemarks,
      { headers }
    );
  }

  editTaskHistoryRemarks(data: EditTaskRemarks): Observable<any> {
    return this.http.post(`${this.baseUrl}editTaskRemarksAsync`, data);
  }

  deleteEnquiryTicketRemarks(taskHistoryInfoId: number, taskInfoId: number, userInfoId:number, roleInfoId:number): Observable<DeleteTaskRemarks[]> {
    return this.http.post<any>(
      `${this.baseUrl}deleteTaskRemarksAsync`,
      { taskHistoryInfoId, taskInfoId, userInfoId, roleInfoId},
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/plain',
        },
      }
    );
  }

  updateEnquiryTicketStatus(
    request: UpdateEnquiryTaskStatusRequest
  ): Observable<any> {
    return this.http.post(`${this.baseUrl}updateTaskStatusAsync`, request);
  }

  updateDueDate(dueDate: UpdateDueDate): Observable<any> {
    return this.http.post(this.updateTaskDueDateUrl, dueDate);
  }
  
  getTaskInfoOverview(): Observable<TaskInfoOverview[]> {
    const taskInfoIdStr = localStorage.getItem('taskInfoId');
    const taskInfoId = +taskInfoIdStr!;
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();

    return this.http.get<TaskInfoOverview[]>(
      `${this.getTaskInfoOverviewUrl}?CompanyInfoId=${companyInfoId}&TaskInfoId=${taskInfoId}`
    );
  }
}
