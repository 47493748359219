import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphaNumericInput]',
})
export class AlphaNumericInputDirective {
  constructor(@Optional() @Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value: string = input.value;

    // Remove all non-alphanumeric characters
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    // Update the form control value if it exists
    if (this.ngControl) {
      this.ngControl.control?.setValue(value, { emitEvent: false });
    } else {
      // Update the input value directly if not using a form control
      input.value = value;
    }
  }
}
